<template>
    <div @click="openModal">
        <slot></slot>
        <Teleport to="body">
            <Transition>
                <div class="dx-modal" v-if="open">
                    <div class="dx-modal__backdrop" @click="close()" ></div>
                    <div class="dx-modal__container">
                        <div @click="close()" ><dx-icon class="dx-modal__container__close-button" icon="close"/></div>
                        <slot name="modal" :closeModal="close"></slot>
                    </div>
                </div>
            </Transition>
        </Teleport>
    </div>
</template>
<script setup lang="ts">
import { ref, defineExpose } from 'vue';

defineExpose({openModal})

const open = ref(false);
function close() {
    open.value = false;
}
function openModal() {
    open.value = true;
}
</script>
<style lang="scss" scoped>
.dx-modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    padding: 1em;
    overflow: auto;
    z-index: 4;
    &__backdrop {
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 1;
    }
    &__container {
        position: relative;
        max-width: 100%;
        overflow: auto;
        border-radius: 5px;
        background-color: white;
        margin: auto;
        z-index: 2;
        padding-top: 1em;
        
        @media only screen and (max-width: 768px) {
            border-radius: 0;
        }
        &__close-button {
            position: absolute;
            top: 0.5em;
            right: 0.5em;
            cursor: pointer;
        }
    }
}
</style>