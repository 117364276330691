<template>
    <TransitionGroup v-if="useToast.toasts.length > 0" name="list" tag="div" class="dx-toast">
        <div v-for="toast in useToast.toasts" :key="toast.key" class="dx-toast__item dx-card" :class="toast.type" >
            <dx-icon class="dx-toast__item__close" icon="close" @click="close(toast)" />
            <div class="dx-toast__item__title">{{ $t(toast.title ? toast.title : 'toast.' + toast.type) }}</div>
            <div class="dx-toast__item__message">{{ toast.message }}</div>
        </div>
    </TransitionGroup>
</template>
<script lang="ts" setup>
import { IToast } from '@/models/toast';
import { useToastStore } from '@/store/toast';
const useToast = useToastStore();
function close(toast: IToast) {
    useToast.remove(toast);
}
</script>
<style lang="scss" scoped>
.dx-toast {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: fit-content;
    max-width: 100%;
    padding: 1em;
    z-index: 9;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    &__item {
        position: relative;
        border-radius: 4px;
        box-shadow: 6px 3px 35px 0px rgba($color1, $alpha: 0.15);
        width: 350px;
        max-width: 100%;
        background-color: white;
        padding: 10px;
        border-left: 1px solid;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        &.success {
            color: $color1;
        }
        &.error {
            color: $color3;
        }
        &__close {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 15px;
            height: 15px;
            cursor: pointer;
        } 
        &__title {
            font-size: 12px;
            font-weight: 600;
            text-transform: capitalize;
        }
        &__message {
            font-size: 12px;
        }
    }
}
</style>