<template>
    <div class="dx-doc-viewer" ref="docRef" :style="docStyle"></div>
</template>
<script setup lang="ts">
import { IGNORE_SYMBOLS_SEARCH_HIGHLIGHT } from '@/commons/const';
import * as docx from 'docx-preview';
import { ref, defineProps, defineEmits, watch, onMounted, onUnmounted } from 'vue';

const props = defineProps<{
    src: string;
    highlightText?: any;
    page?: number;
    isPreview?: boolean;
}>()
const emits = defineEmits(["onload", "onloadDone", "onloadFail"]);
const docRef = ref();
const docStyle = ref<any>({
    '--doc-scale': 1,
    opacity: 0,
});

watch(() => [props.src, props.highlightText],
() => {
    if(props.src) {
        loadDoc();
    }
}, {
    immediate: true
})

let originDocRect: any;
onMounted(() => {
  window.addEventListener("dxResize", calculateSize);
  window.addEventListener("resize", calculateSize);
})
onUnmounted(() => {
  window.removeEventListener("dxResize", calculateSize);
  window.removeEventListener("resize", calculateSize);
})
function calculateSize() {
  const docContainerRect = docRef.value?.getBoundingClientRect();
  if(docContainerRect) {
    docStyle.value["--doc-scale"] =  docContainerRect?.width / originDocRect?.width;
    docStyle.value['--doc-height'] = originDocRect?.height * docStyle.value["--doc-scale"] + 'px';
    docStyle.value["opacity"] = 1;
  }
}

function loadDoc() {
    emits("onload");
    docRef.value?.scrollIntoView();
    docStyle.value["--doc-scale"] = 1;
    docStyle.value["opacity"] = 0;
    fetch(props.src)
    .then(res => res.blob())
    .then(blob => docx.renderAsync(blob, docRef.value, undefined, {inWrapper: true, useBase64URL: true, ignoreLastRenderedPageBreak: false}))
    .then(() => {
        const docRect = docRef.value.querySelector(".docx").getBoundingClientRect();
        const containerRect = docRef.value.getBoundingClientRect();
        originDocRect = {
          width: docRect.width,
          height: containerRect.height
        };
        calculateSize();
        renderHighlight();

        if(props.isPreview) {
          renderFirstPage();
        }
        emits("onloadDone");
    })
    .catch(err => {
        emits("onloadFail", err);
    })
    .finally(() => {
      //
    });
}

function renderFirstPage() {
  docRef.value.querySelectorAll(".docx").forEach((page: any, index: number) => {
    if (index !== 0) { 
      page.remove();
    }
  });
}

function renderHighlight() {
  if(props.highlightText && docRef.value) {
    const divs = Array.from(document.querySelectorAll('.dx-doc-viewer .docx span'));
    const textContent = divs.map((item: any) => item.innerText);
    const textContentItemsStr: any = [];
    for (const textItem of textContent) {
      textContentItemsStr.push(textItem);
    }
    const regexStr = props.highlightText
                      .replace(new RegExp(`[${IGNORE_SYMBOLS_SEARCH_HIGHLIGHT.join('')}]`, 'g'), ' ')
                      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                      .replace(/\s+/g, '\\s*');
    const regex = new RegExp(regexStr, 'gi');
    const {matches, matchesLength} = calculateRegExpMatch(regex, textContentItemsStr.join("").replace(new RegExp(`[${IGNORE_SYMBOLS_SEARCH_HIGHLIGHT.join('')}]`, 'g'), ' '));
    const result = convertMatches(matches, matchesLength, textContentItemsStr.map((item: string)=>item.replace(new RegExp(`[${IGNORE_SYMBOLS_SEARCH_HIGHLIGHT.join('')}]`, 'g'), ' ')));
    renderMatches(result, textContentItemsStr, divs)
    setTimeout(() => {
      if(document.querySelector(".highlight.selected")) {
        docRef.value.querySelector(".highlight.selected")?.scrollIntoView({block: 'center'});
      } else if(props.page) {
        docRef.value.querySelectorAll(".docx-wrapper .docx")?.[props.page - 1]?.scrollIntoView({block: 'center'});
      }
    }, 0);
  }
}

function convertMatches(matches: number[], matchesLength: number[], textContentItemsStr: any, ) {
    // Early exit if there is nothing to convert.
    if (!matches) {
      return [];
    }
    let i = 0,
      iIndex = 0;
    const end = textContentItemsStr.length - 1;
    const result = [];

    for (let m = 0, mm = matches.length; m < mm; m++) {
      // Calculate the start position.
      let matchIdx = matches[m];

      // Loop over the divIdxs.
      while (i !== end && matchIdx >= iIndex + textContentItemsStr[i].length) {
        iIndex += textContentItemsStr[i].length;
        i++;
      }

      if (i === textContentItemsStr.length) {
        console.error("Could not find a matching mapping");
      }

      const match: any = {
        begin: {
          divIdx: i,
          offset: matchIdx - iIndex,
        },
      };

      // Calculate the end position.
      matchIdx += matchesLength[m];

      // Somewhat the same array as above, but use > instead of >= to get
      // the end position right.
      while (i !== end && matchIdx > iIndex + textContentItemsStr[i].length) {
        iIndex += textContentItemsStr[i].length;
        i++;
      }

      match.end = {
        divIdx: i,
        offset: matchIdx - iIndex,
      };
      result.push(match);
    }
    return result;
  }

function renderMatches(matches: any[], textContentItemsStr: any, textDivs: any) {
  // Early exit if there is nothing to render.
  if (matches.length === 0) {
    return;
  }
  let prevEnd = null;
  const infinity = {
    divIdx: -1,
    offset: undefined,
  };

  function beginText(begin: any, className?: any) {
    const divIdx = begin.divIdx;
    textDivs[divIdx].textContent = "";
    return appendTextToDiv(divIdx, 0, begin.offset, className);
  }

  function appendTextToDiv(divIdx: any, fromOffset: any, toOffset: any, className?: any) {
    let div = textDivs[divIdx];
    if (div.nodeType === Node.TEXT_NODE) {
      const span = document.createElement("span");
      div.before(span);
      span.append(div);
      textDivs[divIdx] = span;
      div = span;
    }
    const content = textContentItemsStr[divIdx].substring(
      fromOffset,
      toOffset
    );
    const node = document.createTextNode(content);
    if (className) {
      const span = document.createElement("span");
      span.className = `${className} appended`;
      span.append(node);
      div.append(span);
      return className.includes("selected") ? span.offsetLeft : 0;
    }
    div.append(node);
    return 0;
  }

  let i0 = 0, i1 = matches.length;

  let lastDivIdx = -1;
  let lastOffset = -1;
  for (let i = i0; i < i1; i++) {
    const match = matches[i];
    const begin = match.begin;
    if (begin.divIdx === lastDivIdx && begin.offset === lastOffset) {
      // It's possible to be in this situation if we searched for a 'f' and we
      // have a ligature 'ff' in the text. The 'ff' has to be highlighted two
      // times.
      continue;
    }
    lastDivIdx = begin.divIdx;
    lastOffset = begin.offset;

    const end = match.end;
    let selectedLeft = 0;

    const isSelected = i == 0;
    const highlightSuffix = isSelected ? " selected" : "";

    // Match inside new div.
    if (!prevEnd || begin.divIdx !== prevEnd.divIdx) {
      // If there was a previous div, then add the text at the end.
      if (prevEnd !== null) {
        appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset);
      }
      // Clear the divs and set the content until the starting point.
      beginText(begin);
    } else {
      appendTextToDiv(prevEnd.divIdx, prevEnd.offset, begin.offset);
    }

    if (begin.divIdx === end.divIdx) {
      selectedLeft = appendTextToDiv(
        begin.divIdx,
        begin.offset,
        end.offset,
        "highlight" + highlightSuffix
      );
    } else {
      selectedLeft = appendTextToDiv(
        begin.divIdx,
        begin.offset,
        infinity.offset,
        "highlight begin" + highlightSuffix
      );
      for (let n0 = begin.divIdx + 1, n1 = end.divIdx; n0 < n1; n0++) {
        textDivs[n0].className = "highlight middle" + highlightSuffix;
      }
      beginText(end, "highlight end");
    }
    prevEnd = end;

  }

  if (prevEnd) {
    appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset);
  }
}

function calculateRegExpMatch(query: any, pageContent: any) {
    let matches = [], matchesLength = [];
    if (!query) {
      return {matches: [],
        matchesLength: []};
    }
    let match;
    while ((match = query.exec(pageContent)) !== null) {
      if (match.index === query.lastIndex) {
          query.lastIndex++;
      }

      if (match[0].length) {
        matches.push(match.index,);
        matchesLength.push(match[0].length);
      }
    }
    return {
      matches: matches,
      matchesLength: matchesLength
    }
  }

</script>
<style lang="scss" scoped>
.dx-doc-viewer {
  height: var(--doc-height);
  overflow: hidden;
  background-color: white;
  :deep(.docx-wrapper) {
      width: fit-content;
      padding: 0;
      transform-origin: 0 0;
      transform: scale(var(--doc-scale));
      .docx {
        margin: 0;
        &:not(:last-child) {
          margin-bottom: 1px;
        }
        box-shadow: none;
        span.highlight {
          background-color: #ffff0020;
        }
      }
  }
}
</style>