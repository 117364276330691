<template>
    <div class="dx-input dx-searchbar" :class="{'show-suggest': showSuggest}">
        <dx-icon class="only-desktop" icon="search"/>
        <form @submit="formSubmit" style="width: 100%;">
            <input
                ref="keywordRef"
                enterkeyhint="search"
                :placeholder="hasResult ? $t('fileSearchPage.continueSearchPlaceHolder'): $t('fileSearchPage.searchPlaceholder')"
                v-model="keyword"/>
        </form>
        <div class="search-mode">
            <DxSelectBox v-model="_mode" :options="modes" @on-select="onModeChange()"/>
        </div>
        <div class="dx-searchbar__suggest suggest" v-show="showSuggest">
            <div class="suggest__header" v-if="mode == Mode.Answer">
                <dx-icon icon="suggest-answer" />
                Keywords associated with file collection:
            </div>
            <div class="suggest__header" v-else>
                <dx-icon icon="suggest-search" />
                Suggested questions for these files:
            </div>
            <div class="suggest__body">
                <div class="suggest__body__item" v-for="(suggest, index) in searchSuggests" :key="index">
                    <dx-icon icon="search"/>
                    {{ suggest.text }} <span class="hint-color">[write here or select keywords]</span>?
                </div>
            </div>
            <div class="suggest__footer"></div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { IOption } from '@/models/option';
import { Mode } from '@/models/search-result';
import { ref, defineProps, defineEmits, defineExpose, watch } from 'vue';
import DxSelectBox from './DxSelectBox.vue';

const modes: Array<IOption> = [
    { label: 'Search Mode', value: Mode.Search, icon: 'mode_search' },
    { label: 'Answer Mode', value: Mode.Answer, icon: 'mode_answer' }
];
const props = defineProps<{
    hasResult: boolean;
    mode: Mode
}>()
defineExpose({
    focus
})
const keywordRef = ref();
const keyword = ref();
const _mode = ref();
const showSuggest = ref(false);
const searchSuggests = ref([
    {
        text: "What are the specific files or themes related to",
    },
    {
        text: "Which file contains the most information on",
    },
    {
        text: "Are there any contradictions or differences in information between these",
    },
    {
        text: "What are the key sections or chapters that treat of",
    },
])
const answerSuggests = ref([
    {
        text: "Patrimomy",
        fileCnt: 9,
        collectionCnt: 1,
        modified: new Date(),
        modifiedBy: 'Melodie Le Corre'
    },
    {
        text: "Panaroma",
        fileCnt: 9,
        collectionCnt: 1,
        modified: new Date(),
        modifiedBy: 'Melodie Le Corre'
    },
    {
        text: "Panel",
        fileCnt: 9,
        collectionCnt: 1,
        modified: new Date(),
        modifiedBy: 'Melodie Le Corre'
    },
    {
        text: "Recycle paper",
        fileCnt: 9,
        collectionCnt: 1,
        modified: new Date(),
        modifiedBy: 'Melodie Le Corre'
    },
])
watch(() => props.mode,
() => {
    _mode.value = props.mode;
}, {
    immediate: true
})

const emits = defineEmits(["onKeywordChange", "onModeChange"]);

function onModeChange() {
    emits("onModeChange", _mode.value);
}

function formSubmit(e:Event) {
    e.preventDefault()
    keywordRef.value?.blur(); 
    emits("onKeywordChange", keyword.value);
    keyword.value = ''; 
}

function focus() {
    keywordRef.value.focus();
}
</script>
<style lang="scss" scoped>
.dx-searchbar {
    position: relative;
    height: 44px;
    width: 100%;
    background-color: white;
    box-shadow: none;
    padding: 0;
    transition: border-radius 0.15s;
    &.show-suggest {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        outline: none;
        border: none;
        border-top: 1px solid white;
        border-bottom: 1px solid rgba($color1, 0.15);
        .search-mode {
            opacity: 0;
            pointer-events: none;
        }
    }
    &__suggest {
        position: absolute;
        left: 0;
        bottom: -1px;
        transform: translateY(100%);
        width: 100%;
        border-radius: 0 0 8px 8px;
        color: rgba($color1, 0.8);
        background-color: white;
        z-index: 3;
        font-size: 16px;
    }
    .suggest {
        display: flex;
        flex-direction: column;
        .hint-color {
            color: rgba($color1, 0.4)
        }
        &__header {
            display: flex;
            align-items: center;
            padding: 0 15px;
            line-height: 3;
            svg {
                margin-right: 0.5em;
            }
        }
        &__body {
            display: flex;
            flex-direction: column;
            padding: 0 8px 1em;
            &__item {
                cursor: default;
                line-height: 2.5;
                border-radius: 8px;
                padding: 0 7px;
                &:hover {
                    background-color: rgba($color1, 0.05);
                }
                >svg {
                    opacity: 0.3;
                    margin-right: 0.75em;
                }
            }
        }
        &__footer {
            height: 44px;
            width: 100%;
            border-top: 1px solid rgba($color1, 0.15);
            margin-top: auto;
        }
    }
    >svg {
        margin-left: 15px;
        opacity: 0.3;
    }
    input {
        border: none !important;
        outline: none !important;
        width: 100%;
        height: 100%;
        background-color: transparent;
        padding: 0 1em;
        font-size: 16px;
    }

    .search-mode {
        width: fit-content;
        height: 34px;
        margin-right: 5px;
        :deep(.dx-select) {
            border-radius: 8px;
            width: 100%;
            font-size: 12px;
            outline: 1px solid rgba($color1, 0.15);
            border: none;
            .dx-select-input {
                padding: 0 10px;
                border: none;
                border-radius: inherit;
                @media only screen and (max-width: 768px), (max-aspect-ratio: 1/1) {
                    padding: 0 5px;
                }
                .toggle-icon {
                    margin-left: 1em;
                }
            }
            &.open {
                .dx-select-options {
                    top: -5px;
                    left: -5px;
                    border-radius: 8px;
                    outline: 1px solid rgba($color1, 0.15);
                    border: none;
                    padding: 5px;
                    width: calc(100% + 10px);
                    .option {
                        height: 34px;
                        padding: 0 10px;
                        @media only screen and (max-width: 768px), (max-aspect-ratio: 1/1) {
                            padding: 0 5px;
                        }
                        &:not(:last-child) {
                            margin-bottom: 6px;
                        }
                        &.selected:not(:hover) {
                            background-color: white;
                        }
                        &:hover {
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
    }
}
</style>