<template>
    <div class="page-container" :class="{'collapse-files': collapseFiles}">
        <div class="page-container__header">
            <div class="file-search-bar">
                <div class="visibility-hidden only-desktop">
                    <inline-svg :src="require('@/assets/imgs/icons/collection-unfold.svg')" />
                </div>
                <div class="searchbar">
                    <DxSearchBar 
                        ref="keywordRef"
                        :has-result="!!resultSearch"
                        :mode="mode"
                        @on-keyword-change="keyword = $event; onSearch()"
                        @on-mode-change="mode = $event; onModeChange()" />
                </div>
                <div v-if="resultSearch?.resultText || (resultSearch?.chunks?.length??0) > 0">
                    <button class="dx-button dx-button-outline new-action" @click="newTranslation">
                        <inline-svg class="prefix" :src="require('@/assets/imgs/icons/plus.svg')"/>
                        <div class="label only-desktop">{{ $t('fileSearchPage.newSearch') }}</div>
                    </button>
                </div>
            </div>
            <TopBar class="topbar">
                <template v-slot:pageTitle>
                    <div class="dx-page-title">
                        <img src="@/assets/imgs/sidebar/file-search.svg"/>
                        {{ $t('fileSearchPage.title') }}
                    </div>
                </template>
            </TopBar>
        </div>
        <div class="page-container__body"
            ref="bodyRef"
            :style="bodyStype"
            :class="{
                'has-data': resultSearch || (filePageable?.list?.length??0) > 0 || !!currentNavigationState.collection || hasResource,
                'is-preview-file': selectedChunk != undefined
                }">
            <div class="dx-card" id="fileSearchtab">
                <div class="file-search-tab">
                    <div class="file-search-tab__tab" @click="tabIndex = TAB_RESULT; selectedChunk = undefined" :class="{'active': tabIndex == TAB_RESULT}">
                        <dx-icon icon="result"/>
                        <span class="file-search-tab__label">{{ $t('fileSearchPage.result.title')}}</span>
                    </div>
                    <div class="file-search-tab__tab" @click="tabIndex = TAB_FILE; selectedChunk = undefined" :class="{'active': tabIndex == TAB_FILE}">
                        <dx-icon icon="file"/>
                        <span class="file-search-tab__label">{{ $t('fileSearchPage.files.title')}}</span>
                    </div>
                </div>
            </div>
            <Transition>
                <div class="dx-card" id="main" v-if="initDone && (resultSearch || (filePageable?.list?.length??0) > 0 || !!currentNavigationState.collection)" :class="{'tab-active': tabIndex == TAB_RESULT, 'loading': searchLoading}">
                    <DxSpinner class="spinner"/>
                    <div class="illustration-image" v-show="!resultSearch">
                        <span class="text" v-html="$t('fileSearchPage.hasFileText')"></span>
                        <span class="space"></span>
                        <img  src="@/assets/imgs/filesearch/RAG_illustration_3 1.svg" />
                    </div>
                    <DxFileSearchResult class="dx-card-body"
                        v-show="resultSearch"
                        :writeDone="writeDone"
                        :data="resultSearch"
                        :selected-chunk="selectedChunk"
                        @newSearch="newTranslation"
                        @regeneration="onSearch(resultSearch?.title)"
                        @onViewFile="previewFile"/>
                </div>
            </Transition>
            <Transition>
                <div class="dx-card" id="filePreview" v-if="initDone && selectedChunk?.file">
                    <div class="filePreview-backdrop" @click="selectedChunk = undefined"></div>
                    <div class="dx-card__header">
                        <div class="action-button" @click="(selectedChunk.file as File).download()">
                            <dx-icon icon="download"></dx-icon>
                            <div class="label only-desktop">
                                {{ $t('fileSearchPage.files.download') }}
                            </div>
                        </div>
                        <span class="file-name">
                            <div class="dx-file-badge">
                                <inline-svg v-if="selectedChunk.file?.type" :src="require(`@/assets/imgs/icons/file-type-sm-${selectedChunk.file?.type}.svg`)"/>
                                <span>{{ selectedChunk.file.name }}</span>
                            </div>
                        </span>
                        <div class="action-button" @click="selectedChunk = undefined">
                            <dx-icon icon="close"></dx-icon>
                        </div>
                    </div>
                    <div class="dx-card__body">
                        <DxFileViewer :file="selectedChunk.file" :highlight-text="selectedChunk.text" :page="selectedChunk.page?.[0]"/>
                    </div>
                </div>
            </Transition>
            <Transition>
                <div class="dx-card" id="files" v-if="initDone" :class="{'tab-active': tabIndex == TAB_FILE}" ref="dropArea">
                    <div class="dx-card-header" v-if="resultSearch || (filePageable?.list?.length??0) > 0 || !!currentNavigationState.collection">
                        <div class="title">
                            <dx-icon icon="file"/>
                            <span>{{ $t('fileSearchPage.files.title')}}</span>
                        </div>

                        <DxModal class="new-collection-button">
                            <dx-icon icon="new-collection"/>
                            <template v-slot:modal="{closeModal}">
                                <DxNewCollection 
                                    @onUpdated="closeModal(); fetchResourceList()"
                                    :parent-id="currentNavigationState.collection?.id"/>
                            </template>
                        </DxModal>
                        <div class="view-type">
                            <div :class="{'active': viewType == 0}" @click="viewTypeChange(0)">
                                <inline-svg :src="require('@/assets/imgs/icons/view-list.svg')"></inline-svg>
                            </div>
                            <div :class="{'active': viewType == 1}" @click="viewTypeChange(1)">
                                <inline-svg :src="require('@/assets/imgs/icons/view-grid.svg')"></inline-svg>
                            </div>
                        </div>
                    </div>
                    <div class="file-list-navigation">
                        <div class="file-list-navigation__controls">
                            <dx-icon icon="chevron-left" :class="{'active': currentNavigationState.prev}" @click="back"/>
                            <dx-icon icon="chevron-right" :class="{'active': currentNavigationState.next}" @click="next" />
                        </div>
                        <div class="file-list-navigation__breadcrumb">
                            <dx-icon icon="folder-sm" @click="goToCollection(undefined)"/>
                            <template v-for="collection in collectionPath" :key="collection?.id??0">
                                <dx-icon icon="chevron-right" v-if="collection"/>
                                <div class="collection-name" v-if="collection" @click="goToCollection(collection)">
                                    {{ collection.name  }}
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="dx-card-body" ref="fileListRef"  v-if="(filePageable?.list?.length??0) > 0">
                        <DxFileList v-if="(filePageable?.list?.length??0) > 0" :view-type="viewType" :data="filePageable" @on-view-resource="viewResouce($event)"/>
                    </div>
                    <div class="dx-card-footer">
                        <div class="drop-area">
                            <div class="dx-upload-button" :class="{'dupble-border': !(resultSearch || (filePageable?.list?.length??0) > 0)}" ref="uploadButton">
                                <div class="dx-upload-button__body">
                                    <dx-icon class="only-desktop" icon="upload" />
                                    <dx-icon class="only-mobile" icon="plus" />
                                    <p class="dx-upload-button__body__label" v-html="$t('fileSearchPage.files.uploadFile')">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
            <div id="mainResizer" class="resizer"
                v-if="resultSearch || (filePageable?.list?.length??0) > 0 || currentNavigationState.collection || hasResource">
                <div class="resizer__button" @click="toggleView">
                    <dx-icon v-if="collapseFiles" icon="chevron-left"/>
                    <dx-icon v-else icon="chevron-right"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useMock } from '@/commons/http-common';
import DxFileList from '@/components/DxFileList.vue';
import DxFileSearchResult from '@/components/DxFileSearchResult.vue';
import DxFileViewer from '@/components/DxFileViewer.vue';
import DxModal from '@/components/DxModal.vue';
import DxNewCollection from '@/components/DxNewCollection.vue';
import DxSearchBar from '@/components/DxSearchBar.vue';
import DxSpinner from '@/components/DxSpinner.vue';
import TopBar from '@/components/TopBar.vue';
import { useInfiniteScroll } from '@/composables/useInfiniteScroll';
import { useLoading } from '@/composables/useLoading';
import { useToast } from '@/composables/useToast';
import { useUpload } from '@/composables/useUpload';
import { IChunk } from '@/models/chunk';
import { Collection, ICollection } from '@/models/collection';
import { File, IFile } from '@/models/file';
import { IPageAble } from '@/models/page';
import { IResource } from '@/models/resource';
import { Mode } from '@/models/search-result';
import docService from '@/services/doc.service';
import llmService from '@/services/llm.service';
import ragService from '@/services/rag.service';
import { useSearchStore } from '@/store/search';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const bodyRef = ref();
const collapseFiles = ref(false);

const { t } = useI18n();
const searchStore = useSearchStore();
const ITEM_PER_PAGE = 20;

type navigationState = {
    collection?: ICollection;
    next?: navigationState;
    prev?: navigationState;
}
const currentNavigationState = ref<navigationState>({collection: undefined});
const collectionPath = computed<Array<ICollection | undefined>>(() => {
    if(!currentNavigationState.value.collection) {
        return [undefined];
    }
    let current: ICollection = currentNavigationState.value.collection;
    const result: Array<ICollection| undefined> = [current];
    while(current.parent){
        result.unshift(current.parent);
        current = current.parent;
    }
    return result;
})
const filePageable = ref<IPageAble<IResource>>({page: 0, itemPerPage: ITEM_PER_PAGE});
const selectedChunk = ref<IChunk | undefined>();

const keyword = ref("");
const keywordRef = ref();
const resultSearch = ref<{mode?: Mode, title: string, resultText?: string, chunks?: IChunk[]}>();
const searchLoading = ref(false);
const writeDone = ref(false);

const mode = ref(Mode.Search);

const dropArea = ref();
const uploadButton = ref();

const fileListRef = ref();

const TAB_RESULT = 0;
const TAB_FILE = 1;
const TAB_CHUNK = 3;
const tabIndex = ref(TAB_FILE);

const initDone = ref(false);
const hasResource = ref(false);

useInfiniteScroll(fileListRef, async () => {
    if(((filePageable.value.page + 1) * filePageable.value.itemPerPage) >= (filePageable.value.total ?? 0)) return;
    filePageable.value.page++;
    const result = await docService.getDocs(filePageable.value.page, ITEM_PER_PAGE, currentNavigationState.value.collection?.id);
    filePageable.value.list?.push(...result.list??[]);
})

useUpload({dropArea: dropArea, uploadButton: uploadButton, multiple: true, callback: async (files: IFile[]) => {
    if(files && files.length > 0) {
        useLoading(true);
        for(const file of files) {
            try {
                await docService.uploadDoc(file, currentNavigationState.value.collection?.id);
            } catch(err: any) {
                useToast({message: err.message, type: 'error'});
            }
        }
        fetchResourceList();
    }
}});

const viewType = ref(1);

onMounted(() => {
    useLoading(true);
    if(searchStore.fileSearchData) {
        resultSearch.value = searchStore.fileSearchData.result;
        mode.value = searchStore.fileSearchData.queryMode ?? Mode.Search;
        selectedChunk.value = searchStore.fileSearchData.selectedChunk;
        currentNavigationState.value = searchStore.fileSearchData.currentNavigationState
    }
    
    if(resultSearch.value) {
        writeDone.value = true;
        initDone.value = true;
    }
    docService.getDocs(0, ITEM_PER_PAGE, currentNavigationState.value.collection?.id)
    .then(data => {
        filePageable.value = data;
    })
    .catch((err) => {
        useToast({message: err.message, type: 'error'});
    })
    .finally(() => {
        useLoading(false);
        tabIndex.value = (filePageable.value?.list?.length??0) > 0 ? TAB_RESULT : TAB_FILE;
        initDone.value = true;
    });

    calculateWidth();
    window.addEventListener('resize', calculateWidth);
});

function fetchResourceList() {
    useLoading(true);
    docService.getDocs(0, ITEM_PER_PAGE, currentNavigationState.value.collection?.id)
        .then(data => {
            filePageable.value = data;
        })
        .catch((err) => {
            useToast({message: err.message, type: 'error'});
        })
        .finally(() => {
            useLoading(false);
            initDone.value = true;
        });
}

function calculateWidth() {
    if(bodyRef.value) {
        const bodyRect = bodyRef.value.getBoundingClientRect();
        bodyRef.value.style.setProperty("--max-main-width", bodyRect.width * 0.6 + 'px');  
    }
}
function onModeChange() {
    resultSearch.value = undefined;
    selectedChunk.value = undefined;
    reader?.cancel();
    if(mode.value == Mode.Search && tabIndex.value == TAB_CHUNK) {
        tabIndex.value = TAB_RESULT;
    }
}

function onSearch(_keyword?: string) {
    selectedChunk.value = undefined;
    const searchText = _keyword || keyword.value;
    if(searchText) {
        resultSearch.value = {
            title: searchText,
            resultText: '',
            chunks: []
        };
        switch (mode.value) {
            case Mode.Search:
                search(searchText);
                break;
            case Mode.Answer:
                query(searchText);
                break;
            default:
                break;
        }
    }
}

function search(searchText: string) {
    searchLoading.value = true;
    resultSearch.value!.mode = mode.value;
    ragService.search(searchText, currentNavigationState.value.collection?.id)
    .then((chunks: IChunk[]) => {
        resultSearch.value!.chunks = chunks;
        // set default file preview
        const mobileView = window.matchMedia("(max-width: 786px)")
        if(!mobileView.matches) {
            selectedChunk.value = chunks.length > 0 ? [...chunks].sort((a, b) => a.file.id! - b.file.id!)[0] : undefined;
        }
    })
    .catch((err) => {
        useToast({message: err.message, type: 'error'});
    })
    .finally(() => {
        searchLoading.value = false;
    });
    keyword.value = '';
}

function query(searchText: string) {
    resultSearch.value!.mode = mode.value;
    searchLoading.value = true;
    reader?.cancel();
    writeDone.value = false;
    tabIndex.value = TAB_RESULT;
    llmService.search(searchText, currentNavigationState.value.collection?.id, (filePageable.value?.total ?? 0) > 0)
    .then((data) => {
        useLoading(false);
        searchLoading.value = false;
        writeResult(data);
    })
    .catch((err) => {
        useToast({message: err.message, type: 'error'});
    })
    .finally(() => {
        useLoading(false);
        searchLoading.value = false;
    });
    
    keyword.value = '';
}

let reader: ReadableStreamDefaultReader; 
async function writeResult(stream: any) {
    useLoading(false);
    reader = stream.getReader();
    const decoder = new TextDecoder();

    let { done, value } = await reader.read();
    let decoded = '';

    while (!done || decoded !== '') {
        try {
            decoded += decoder.decode(value);
        } catch (error) {
            useToast({type: "error", message: t("errorMessage.readingStreamData")});
        }

        if (!done && !decoded.includes('\n')) {
            ({ done, value } = await reader.read());
            continue;
        }

        const lines = decoded.split('\n');
        for (let line of lines) {
            if (line === '') {
                continue;
            }
            try {
                parseResponse(line);
            } catch (error) {
                useToast({type: "error", message: t("errorMessage.readingStreamData")});
            }
        }
        decoded = '';
        ({ done, value } = await reader.read());
    }

    writeDone.value = true;
}

function parseResponse(line: string) {
    const content = JSON.parse(line);
    if (content?.content !== undefined) {
        resultSearch.value!.resultText += content.content;
    }
    
    if (content?.chunks !== undefined) {
        const chunks: IChunk[] = content.chunks.map((item: any, index: number) => {
            return {
                id: index,
                text: item.text,
                page: item.page,
                file: new File({
                        id: item.id,
                        fileName: item.name,
                        type: item.format,
                        url:  `${useMock ? '' : process.env.VUE_APP_API_URL}${item.url}`
                    }),
                };
        })
        resultSearch.value!.chunks = chunks;
        // set default file preview
        const mobileView = window.matchMedia("(max-width: 786px)")
        if(!mobileView.matches) {
            selectedChunk.value = chunks.length > 0 ? [...chunks].sort((a, b) => a.file.id! - b.file.id!)[0] : undefined;
        }
    }
}

function viewTypeChange(type: number){
    viewType.value = type;
}

function newTranslation() {
    reader?.cancel();
    resultSearch.value = undefined;
    tabIndex.value = TAB_FILE;
    writeDone.value = false;
    selectedChunk.value = undefined;
    searchStore.fileSearchData = null;
    keywordRef.value?.focus();
}

function viewResouce(resource: IResource) {
    if(resource.resourceType == 'file'){
        previewFile({file: resource as File})
    } else if(resource.resourceType == 'collection') {
        let rootParent = resource;
        while(rootParent.parent && !!rootParent.parent?.name) {
            rootParent = rootParent.parent;
        }
        rootParent.parent = currentNavigationState.value.collection;
        goToCollection(resource);
    }
}

function goToCollection(collection: ICollection | undefined) {
    hasResource.value = true;
    const next = {
        collection: collection,
        prev: currentNavigationState.value
    }
    currentNavigationState.value.next = next;
    currentNavigationState.value = next
    fetchResourceList();
}

function back() {
    currentNavigationState.value = currentNavigationState.value.prev!;
    fetchResourceList();
}
function next() {
    if(currentNavigationState.value.next) {
        currentNavigationState.value = currentNavigationState.value.next;
        fetchResourceList();
    }
}
 
function previewFile(chunk: IChunk) {
    selectedChunk.value = chunk;
}

const bodyStype = ref<any>({})
const resizing = ref(false);

function resizer(e: MouseEvent, vertical?: boolean) {
  window.addEventListener('mousemove', mousemove);
  window.addEventListener('mouseup', mouseup);
  
  let prevX = e.x;
  let prevY = e.y;
  const currentWidth = bodyStype.value["--mainWidth"];
  const currentHeight = bodyStype.value["--filesHeight"];
  const bodyRect = bodyRef.value.getBoundingClientRect();
  
  function mousemove(e: MouseEvent) {
    window.dispatchEvent(new Event('dxResize'));
    resizing.value = true;
    if(vertical) {
        let newY = prevY - e.y;
        bodyStype.value["--filesHeight"] = Math.min(Math.max(Number(currentHeight.replace("px", "")) - newY, 0), bodyRect.height) + "px";
    } else {
        let newX = prevX - e.x;
        bodyStype.value["--mainWidth"] = Math.min(Math.max(Number(currentWidth.replace("px", "")) - newX, 0), bodyRect.width) + "px";
    }
  }
  
  function mouseup() {
    resizing.value = false;
    window.removeEventListener('mousemove', mousemove);
    window.removeEventListener('mouseup', mouseup);
  }
}

function toggleView() {
    collapseFiles.value =! collapseFiles.value;
    setTimeout(() => {
        window.dispatchEvent(new Event('dxResize'));
    }, 0);
}

onUnmounted(() => {
    reader?.cancel();
    useLoading(false);
    searchStore.fileSearchData = {
        queryMode: mode.value,
        result: resultSearch.value,
        selectedChunk: selectedChunk.value,
        currentNavigationState: currentNavigationState.value
    }
})
</script>
<style lang="scss" scoped>

@mixin tab-badge {
    width: 19px;
    height: 19px;
    min-width: 19px;
    border: 1px solid currentcolor;
    color: $color4;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.page-container {
    --mainWidth: 60%;
    --filesWidth: 40%;
    --resizer-size: 1px;
    &.collapse-files {
        --mainWidth: 85%;
        --filesWidth: 15%;
        .dx-card .dx-card-header {
            padding-left: 1.5em !important;
        }
    }
}
.page-container__header {
    flex-wrap: wrap-reverse;
    .file-search-bar {
        flex: 1;
        min-width: 0;
        display: flex; 
        align-items: center;
        margin-right: 30px;
        .searchbar {
            flex: 1;
        }
        .new-action {
            margin-left: 15px;
        }
    }
}
.dx-card {
    border-radius: 0;
}
.page-container__body {
    position: relative;
    flex: 1;
    min-height: 0;
    margin-top: 16px;
    display: grid;
    grid-template-areas: "files";
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: -15px;
    // transition: 0.1s;
    &.is-preview-file {
        // grid-template-columns: 60% var(--resizer-size) auto !important;
        // grid-template-areas: "main mainResizer files";
        .resizer {
            &__button {
                display: none;
            }
        }
    }
    &.has-data {
        grid-template-columns: auto var(--resizer-size) max(var(--filesWidth), 200px);
        grid-template-areas: "main mainResizer files";
        #main {
            grid-area: main;
            display: block;
            position: relative;
        }
        #files {
            grid-area: files;
            display: flex;
            flex-direction: column;
            .dx-card-body {
                padding: 0;
                overflow-x: hidden;
            }
            .dx-card-footer {
                padding: 30px;
                height: auto;
                .drop-area {
                    min-height: 90px;
                    height: 100%;
                    width: 100%;
                    .dx-upload-button {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: initial;
                        padding: 12px;
                        max-width: initial;
                        max-height: initial;
                        background-image:
                            repeating-linear-gradient(0deg, rgba($color1, 0.3), rgba($color1, 0.3) 10px, transparent 0px, transparent 15px),
                            repeating-linear-gradient(90deg, rgba($color1, 0.3), rgba($color1, 0.3) 10px, transparent 0px, transparent 15px),
                            repeating-linear-gradient(180deg, rgba($color1, 0.3), rgba($color1, 0.3) 10px, transparent 0px, transparent 15px),
                            repeating-linear-gradient(270deg, rgba($color1, 0.3), rgba($color1, 0.3) 10px, transparent 0px, transparent 15px);
                        svg {
                            width: 40px;
                            height: 100%;
                        }
                        p {
                            display: none;
                        }
                    }
                }
            }
        }
        #mainResizer {
            grid-area: mainResizer;
        }
        #chunk, #filesResizer {
            display: none;
        }
        
    }
    #main {
        .spinner {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
        }
        &.loading {
            .spinner {
                display: block;
            }
        }
        .illustration-image {
            width: 80%;
            height: 80%;
            top: 10%;
            left: 10%;
            position: absolute;
            color: rgba($color1, 0.4);
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 8px;
            &::before, &::after {
                content: "";
                height: 25%;
                width: 100%;
                display: block;
                @media only screen and (max-width: 768px) {
                    height: 20%;
                }
            }
            .text {
                max-width: 80%;
                text-align: center;
                font-size: 16px;
            }
            .space {
                height: 10%;
                width: 100%;
                display: block;
            }
            img {
                max-width: 40%;
                min-height: 0;
                flex: 1
            }
        }
    }
    #files, #chunk {
        .dx-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1em 1em 1em 2em;
            font-weight: 600;
            font-size: 14px;
            border-bottom: 1px solid rgba($color1, 0.1);
            color: $color1;
            .title {
                display: flex;
                align-items: center;
                > * {
                    margin-right: 0.5em;
                }
                &__badge {
                    @include tab-badge();
                }
            }
            .view-type {
                color: $color1;
                display: flex;
                align-items: center;
                div {
                    border: 1px solid rgba($color1, 0.15);
                    background-color: white;
                    height: 24px;
                    padding: 0 0.5em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    &.active {
                        background-color: rgba($color1, 0.05);
                    }
                }
                :first-child {
                    border-radius: 5px 0 0 5px;
                }
                :last-child {
                    border-radius: 0 5px 5px 0;
                }
            }
        }
    }
    
    #chunk {
        display: flex;
        flex-direction: column;
        .dx-card-body {
            flex: 1;
            min-height: 0;
            overflow: auto;
            padding: 25px 0 25px;
            padding-left: 35px;
            padding-right: 35px; 
        }
    }
    #files {
        .new-collection-button {
            margin-left: auto;
            margin-right: 1em;
            color: $color4;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-self: center;
        }
        .dx-card-body {
            overflow: auto;
            padding: 25px 0 25px;
        }
        .dx-card-footer {
            width: 100%;
            height: 100%;
            min-height: fit-content;
            flex: 1;
        }
    }
    #filePreview {
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        height: 100%;
        overflow: hidden;
        z-index: 2;
        background-color: #EAEAEC;
        display: flex;
        flex-direction: column;
        .filePreview-backdrop {
            display: none;
        }
        .dx-card__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            padding: 0 30px;
            height: 70px;
            line-height: 70px;
            .file-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 1em;
            }
            .action-button {
                display: flex;
                align-items: center;
                height: 36px;
                width: fit-content;
                min-width: fit-content;
                font-size: 12px;
                border-radius: 8px;
                overflow: hidden;
                cursor: pointer;
                &:hover {
                    color: rgba($color1, 0.6);
                }
                @media only screen and (max-width: 768px), (max-aspect-ratio: 1/1) {
                    height: 32px;
                }
                &:last-child {
                    justify-content: flex-end;
                }
                .label {
                    flex: 1;
                    overflow: hidden;
                    margin-left: 0.5em;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        .dx-card__body {
            padding: 10px 20px 20px;
            flex: 1;
            min-height: 0;
            overflow: auto;
        }
    }
    .dx-card {
        position: relative;
        background-color: rgba($color: white, $alpha: 0.6);
        border: none;
    }
    .drop-area {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
#fileSearchtab {
    display: none;
}
.resizer {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
    background-color: white;
    &::before, &::after {
        content: "";
        width: 1px;
        flex: 1;
        background-color: rgba($color1, 0.15);
    }
    &__button {
        position: relative;
        z-index: 3;
        width: 20px;
        height: 40px;
        background-color: #F0F0F1;
        border-radius: 5px;
        border: 1px solid rgba($color1, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
            height: 10px;
            width: auto;
        }
    }

}

.new-action {
    height: 36px;
    background-color: white;
    border: 1px solid $color4;
    color: $color4;
    font-size: 12px;
    svg.prefix {
        margin-right: 0;
    }
    .label {
        margin-left: 0.5em;
    }
}
@media only screen and (max-width: 768px), (max-aspect-ratio: 1/1) {
    .page-container {
        &__header {
            gap: 0;
            .file-search-bar {
                padding: 15px 20px;
                margin-right: 0;
                .new-action {
                    margin-left: 10px;
                }
            }
        }
        &__body, &__body.has-data {
            width: 100%;
            margin: 0;
            margin-top: 0;
            min-height: 0;
            grid-template-areas: "tabHeader" "tabContent";
            grid-template-rows: auto 1fr;
            grid-template-columns: 100%;
            gap: 5px;
            >.dx-card {
                border-radius: 0px;
                grid-area: tabContent !important;
                .dx-card-header {
                    display: none !important;
                }
                
            }
            .resizer {
                display: none !important;
            }
            #fileSearchtab {
                grid-area: tabHeader !important;
                display: block;
                width: 100%;
                padding: 5px 15px;
                .file-search-tab {
                    background-color: rgba($color1, 0.05);
                    height: fit-content;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 8px;
                    svg {
                        flex-shrink: 0;
                    }
                    &__tab {
                        display: flex;
                        border-radius: 8px;
                        align-items: center;
                        justify-content: center;
                        flex: 1;
                        height: 30px;
                        overflow: hidden;
                        margin: 5px;
                        padding: 0 5px;
                        &.active {
                            background-color: white;
                            box-shadow: 0 0 0 1px rgba($color1, 0.15);
                        }

                    }
                    &__label {
                        margin-left: 5px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    &__badge {
                        margin-left: 5px;
                        @include tab-badge();
                    }

                }
            }
            #main, #files, #chunk {
                .dx-card-body {
                    padding: 20px 25px 20px 25px;
                }
                &:not(.tab-active) {
                    display: none;
                }
            }
            #files {
                flex-direction: column-reverse;
                .dx-card-body {
                    padding: 0 25px 20px 25px;
                }
                .dx-card-footer {
                    padding: 30px;
                }
            }
            #filePreview {
                position: fixed;
                top: 0;
                left: 0;
                padding: 1em;
                height: 100vh;
                width: 100vw;
                overflow-y: scroll;
                display: flex;
                .filePreview-backdrop {
                    position: fixed;
                    top: -1em;
                    left: -1em;
                    width: calc(100% + 2em);
                    min-height: calc(100% + 2em);
                    display: block;
                    z-index: -1;
                    background-color: rgba(0, 0, 0, 0.1);
                }
                .dx-card__header {
                    height: 55px;
                    min-height: 55px;
                    background-color: #F0F0F1;
                    padding: 0 20px;
                }
                .dx-card__body {
                    min-height: auto;
                    padding: 20px;
                    overflow: initial;
                    background-color: white;
                }
            }
            &:not(.has-data) {
                gap: 0;
                #fileSearchtab {
                    display: none;
                }
            }
        }
    }

    .new-action {
        height: 32px;
    }
}

.file-list-navigation {
    display: flex;
    align-items: center;
    padding: 7px 7px 7px 0;
    border-bottom: 1px solid rgba($color1, 0.15);
    width: 100%;
    overflow-x: auto;
    min-height: 50px;
    overflow-y: hidden;
    &__controls {
        display: grid;
        align-items: center;
        gap: 1.5em;
        grid-template-columns: 1fr 1fr;
        min-width: fit-content;
        padding: 0 1.5em;
        >svg {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
            &.active {
                opacity: 1;
                cursor: pointer;
                pointer-events: unset;
            }
        }
    }
    &__breadcrumb {
        background-color: white;
        display: flex;
        align-items: center;
        flex: 1;
        padding: 8px 10px;
        font-size: 12px;
        border-radius: 5px;
        border: 1px solid rgba($color1, 0.15);
        color: rgba($color1, 0.6);
        white-space: nowrap;
        >* {
            margin-right: 1em;
            cursor: pointer;
        }
        .collection-name:last-child {
            pointer-events: none;
        }
        .collection-name:hover {
            text-decoration: underline;
        }
    }
}
</style>