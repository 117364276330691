<template>
    <div class="wrapper" @keyup.enter="submit">
        <h5 class="title">{{  $t('changePassword.title') }}</h5>
        <div class="dx-error" v-if="error">⚠ {{ $t(error) }}</div>
        <input name="password" type="password" class="dx-input" :placeholder="$t('changePassword.password')" v-model="password"/>
        <input name="newPassword" type="password" class="dx-input" :placeholder="$t('changePassword.newPassword')" v-model="newPassword"/>
        <input name="newPasswordConfirmation" type="password" class="dx-input" :placeholder="$t('changePassword.newPasswordConfirmation')" v-model="newPasswordConfirmation"/>
        <button class="dx-button dx-button-primary dx-button-sm" :disabled="!password || !newPassword || !newPasswordConfirmation" @click="submit">{{ $t('changePassword.okButton')}}</button>
   </div>
</template>
<script lang="ts" setup>
import { useLoading } from '@/composables/useLoading';
import { useToast } from '@/composables/useToast';
import router from '@/router';
import userService from '@/services/user.service';
import { useAccountStore } from '@/store/account';
import { ref, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

    const { t } = useI18n();
    const password = ref("");
    const newPassword = ref("");
    const newPasswordConfirmation = ref("");
    const error = ref();

    const emits = defineEmits(["onUpdated"])
    const useAccount = useAccountStore();

    function submit() {
        if(newPassword.value !== newPasswordConfirmation.value) {
            error.value = "changePassword.passwordConfirmationMismatch"
            return;
        }
        if(newPassword.value.length < 8) {
            error.value = "changePassword.errorMinLengthPassword";
            return;
        }
        useLoading(true)
        userService.changePassword(password.value, newPassword.value)
            .then(() => {
                emits('onUpdated');
                useToast({type:'success', message: t("changePassword.successMessage")});
                useAccount.account = null;
                localStorage.removeItem('dx-currentLang');
                router.push("login")
            })
            .catch((err) => {
                error.value = err.message
            })
            .finally(() => {
                useLoading(false)    
            })
    }

</script>
<style lang="scss" scoped>
.wrapper {
    max-width: 100%;
    width: 350px;
    padding: 0 2em 2em 2em;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1em;
    .title {
        margin-bottom: 0;
    }
}

.dx-error {
    color: $color3;
    font-size: 12px;
    margin: -15px 0;
}
</style>