import { http, useMock } from "@/commons/http-common";
import { IChunk } from "@/models/chunk";
import { File } from "@/models/file";

class RAGService {
    search(content: string, parentId?: number) {
        return  http.post('/rag/search', {
            content: content,
            parent_id: parentId ?? '',
        })
        .then((response: any) => {
            const data = response.data;
            const list:Array<IChunk> = data.chunks.map((item: any, index: number) => {
                return {
                    id: index,
                    text: item.text,
                    page: item.page,
                    file: new File({
                            id: item.id,
                            fileName: item.name,
                            type: item.format,
                            url:  `${useMock ? '' : process.env.VUE_APP_API_URL}${item.url}`
                        }),
                    };
            })
            return list;
        })
        .catch((error: any) => {
            throw error;
        });
    }
}

export default new RAGService();