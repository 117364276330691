import { formatBytes, getImageUrl } from "@/commons/utils";
import { ICollection } from "./collection";
import { IResource } from "./resource";

interface IFile extends IResource {
    file?: any;
    type?: FileType;
    size?: number;
    url?: string;
}
class File implements IFile{
    resourceType = 'file' as const;
    id?: number;
    name = '';
    file?: any;
    type?: FileType;
    size?: number;
    date = '';
    owner = '';
    url?: string;
    parent?: ICollection;
    constructor(obj: any) {
        if(obj) {
            this.id = obj.id
            this.name = obj.fileName;
            this.file = obj.file;
            this.type = this.format(obj.type?.toLowerCase());
            this.size = obj.size;
            this.url = obj.url;
            this.date = obj.lastModified;
            this.owner = obj.modifiedBy;
            this.parent = obj.parent;
        }
    }
    format(_type: any): FileType {
        if(Object.values(FileType).includes(_type as FileType)) {
            return _type;
        } else {
            return FileType.UNKNOW;
        }
    }
    get sizeFormat() {
        return formatBytes(this.size ?? 0);
    }
    get fileUrl(): string {
        return getImageUrl(this);
    }

    download() {
        window.open(this.url, '_blank');
    }
}

enum FileType {
    PPTX='pptx', DOCX='docx', XLSX='xlsx', IMAGE='image', PDF='pdf', SOUND='sound', TXT='txt', VIDEO='video', UNKNOW='unknow'
}

export { IFile, FileType, File };