<template>
    <div class="dx-file-preview">
        <div v-if="fileSizeExceedsLimit || !loaded || previewType == 'icon'" class="dx-file-preview__icon" :class="{'sm-icon': smallIcon}">
            <dx-icon v-if="fileExt == 'csv'" :icon="`file-type-${smallIcon ? 'sm-' : ''}xlsx`"/>
            <dx-icon v-else :icon="`file-type-${smallIcon ? 'sm-' : ''}${file.type}`"/>

        </div>
        <DxFileViewer
            class="dx-file-preview__preview" 
            v-if="!fileSizeExceedsLimit && (previewType == 'preview' || loaded == true)"
            v-show="previewType == 'preview'" :file="file" :is-preview="true" @on-loaded="loaded = true"/>
    </div>
</template>
<script lang="ts" setup>
import { IFile } from '@/models/file';
import { computed, defineProps, ref } from 'vue';
import DxFileViewer from './DxFileViewer.vue';
import { MAX_PREVIEW_FILE_SIZE } from '@/commons/const';

const props = defineProps<{
    file: IFile,
    previewType: 'icon'| 'preview';
    smallIcon?: boolean;
}>();

const fileSizeExceedsLimit = computed(() => {
    return (props.file.size??0) >= MAX_PREVIEW_FILE_SIZE;
})
const fileExt = computed(() => {
    return props.file.name?.split('.')?.pop()?.toLowerCase()??"";
})
const loaded = ref(false);
</script>
<style lang="scss" scoped>
.dx-file-preview {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
    &__icon {
        width: 100%;
        height: 100%;
        &:not(.sm-icon) {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            svg {
                width: 50%;
                height: auto;
            }
        }
    }
    &__preview {
        background-color: white;
        border: 1px solid rgba($color1, 0.1);
        border-radius: inherit;
        overflow: hidden;
    }
}
</style>