import { useMock } from "@/commons/http-common";
import { File, IFile } from "./file";

interface IHistory {
    id: number;
    method: HistoryMethod;
    prompt: string;
    answer: string;
    modified: string;
    documents: Array<IFile>;
}
class History implements IHistory{
    id: number;
    method: HistoryMethod;
    prompt: string;
    answer: string;
    modified: string;
    documents: IFile[] = [];
    constructor(obj: any) {
        this.id = obj?.id;
        this.method = obj?.method;
        this.prompt = obj?.prompt;
        this.answer = obj?.answer;
        this.documents = obj?.documents?.map((doc:any) => {return new File({
            id: doc.id,
            fileName: doc.name,
            size: doc.size,
            type: doc.format,
            lastModified: doc.modified,
            modifiedBy: `${doc.owner?.first} ${doc.owner?.last}`,
            url: `${useMock ? '' : process.env.VUE_APP_API_URL}${doc.url}`
        })}) ?? [];
        this.modified = obj?.modified;
        if(this.method  == HistoryMethod.TRANSLATE && this.documents.length > 0) {
            this.prompt = (this.documents[0] as File)?.name?? "";
        }
    }

    get methodIcon() {
        switch (this.method) {
            case HistoryMethod.SEACH:
                return "type-document-search";
            case HistoryMethod.TRANSLATE:
                if(this.documents.length > 0) {
                    return "type-file-translate";
                } else {
                    return "type-text-translate";
                }
            default:
                return null;
        } 
    }
    get methodName() {
        switch (this.method) {
            case HistoryMethod.SEACH:
                return "enum.historyMethod.documentSearch";
            case HistoryMethod.TRANSLATE:

                if(this.documents.length > 0) {
                    return "enum.historyMethod.fileTranslation";
                } else {
                    return "enum.historyMethod.translation";
                }
            default:
                return '';
        } 
    }
}
enum HistoryMethod {
    SEACH='SEARCH', TRANSLATE='TRANSLATE'
}
 export { IHistory , History};