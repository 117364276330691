import { HEADERS, http, useMock } from "@/commons/http-common";
import { Collection } from "@/models/collection";
import { File, IFile } from "@/models/file";
import { IPageAble } from "@/models/page";
import { IResource } from "@/models/resource";

class DocService {
    uploadDoc(file: IFile, parentId?: number) {
        const form = new FormData();
        form.append("file", file.file);
        form.set('parent_id', parentId?.toString() ?? '');
        return http.post('/doc/upload', form, {
            headers: HEADERS.form,
        })
        .then(res => { return res.data })
        .catch(err => { throw err });
    }

    getDocs(page: number, size: number, parentId?: number): Promise<IPageAble<IResource>> {
        return http.post('/doc/list', {page: page, size: size, parent_id: parentId},
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then((response: any) => {
            const data = response.data;
            const docs = data.documents.map((doc: any) => new File({
                id: doc.id,
                fileName: doc.name,
                size: doc.size,
                type: doc.format,
                file: doc.file,
                lastModified: doc.modified,
                modifiedBy: `${doc.owner?.first} ${doc.owner?.last}`,
                url: `${useMock ? '' : process.env.VUE_APP_API_URL}${doc.url}`, 
                parent: doc.parent
            }));
            const collections = data.collections.map((collection: any) => new Collection({
                id: collection.id,
                name: collection.name,
                date: collection.modified,
                owner: `${collection.owner?.first} ${collection.owner?.last}`,
                parent: collection.parent,
                isEmpty: collection.is_empty
            }));
            const pageAble:IPageAble<IFile> = {
                list: [...collections, ...docs],
                total: data.total,
                page: page,
                itemPerPage: size
            }
            return pageAble;
        })
        .catch((error: any) => {
            throw error;
        });
    }

    deleteDoc(id: any) {
        return http.post('/doc/delete', {id: id},
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    }
    

}
 export default new DocService();