<template>
  <div ref="target">
    <slot :open="openDropdown" ></slot>

    <teleport to="body">
      <Transition>
        <div class="dx-dropdown" v-if="isOpen">
          <div class="backdrop" @click.stop="isOpen = false"></div>
            <div class="dropdown-wrapper" ref="dropdownRef" :style="dropdownStyle">
                <slot name="dropdown" :close="closeDropdown"></slot>
            </div>
        </div>
      </Transition>
    </teleport>
  </div>
  </template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref, defineProps } from "vue";

const props = defineProps<{
  closeOnContentClick?: boolean
}>()

const isOpen = ref(false);
const target = ref();
const dropdownRef = ref();
const dropdownStyle = ref({
      top: '0',
      left: '0',
      opacity: '0'
    });
function openDropdown(e: Event) {
  e.stopPropagation();
  isOpen.value = true;
  setTimeout(() => {
    updateDropdownPosition()
  }, 0);
}

onMounted(() => {
  window.addEventListener('resize', updateDropdownPosition);
  if(props.closeOnContentClick) {
    document.addEventListener("click", handleCloseOnContentClick);
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', updateDropdownPosition);
  document.removeEventListener("click", handleCloseOnContentClick);
})

function closeDropdown() {
  isOpen.value = false;
}

function handleCloseOnContentClick(event: Event) {
  if (dropdownRef.value && dropdownRef.value.contains(event.target as HTMLElement)) {
    isOpen.value = false;
  }
}

function updateDropdownPosition() {
  if(!isOpen.value || !dropdownRef.value) return;
  const buttonRect = target.value.getBoundingClientRect();
  if(!target.value 
    || (buttonRect.width == 0 && buttonRect.height == 0 && buttonRect.top == 0 && buttonRect.left ==0) ) {
    isOpen.value = false;
  }
  const dropdownRect = dropdownRef.value.getBoundingClientRect();
  
  let top = buttonRect.bottom;
  let left = buttonRect.right;
  
  if (top + dropdownRect.height > window.innerHeight) {
    top = buttonRect.top - dropdownRect.height;
  }
  if (left - dropdownRect.width < 0) {
    left = left - (left - dropdownRect.width) + 10;
  }
  
  dropdownStyle.value.top = `${top}px`;
  dropdownStyle.value.left = `${left}px`;
  dropdownStyle.value.opacity = '1';
  }

</script>

<style scoped lang="scss">
.dx-dropdown {
  cursor: default;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 0;
  }
}
.dropdown-wrapper {
  position: absolute;
  z-index: 3;
  transform: translate(-100%, 5px);
}

</style>