export const LANGUAGES = [
    'en',
    'es',
    'fr',
    'ja',
    'ko',
    'zh',
];

export const TOAST_DELEY_SECCOND = 10;

export const MAX_PREVIEW_FILE_SIZE = 5000000; // 5Mb

export const IGNORE_SYMBOLS_SEARCH_HIGHLIGHT = [
    "|",
]