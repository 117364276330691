<template>
    <template
        v-for="resource in resources"
        :key="resource.id">
        <div class="file-list-item expand-container"
            v-show="show && expand && collectionId && resources.length > 0"
            :style="'--expand-padding:' + ((level* 20) + 'px')"
            @click="onViewResource(resource)">
            <div class="file-list-item__prop name" :title="resource.name">
                <div>
                    <div class="type-icon" :class="{'type-icon--folder': resource.resourceType =='collection'}">
                        <DxFilePreview v-if="resource.resourceType == 'file'" 
                            :file="(resource as IResource)" 
                            :previewType="'icon'" 
                            :small-icon="true" />
                        <div v-else class="folder-preview">
                            <div class="folder-preview__chevron"
                                v-if="!(resource as ICollection).isEmpty"
                                @click="expandCollection($event, resource)">
                                <DxIcon :icon="expandIds.includes(resource.id!) ? 'chevron-down' : 'chevron-top'"/>
                            </div>
                            <DxIcon icon="folder-sm" class="folder-preview__icon"/>
                        </div>
                    </div>
                    <span class="label" :class="{'label--folder': resource.resourceType=='collection'}">
                        <input
                            :id="'name'+resource.id"
                            class="name-input" 
                            v-if="editingResource?.id==resource.id"
                            @click="$event.stopPropagation()"
                            @keyup.enter="updateName(resource)"
                            @focusout="updateName(resource)"
                            v-model="editingResource!.name"/>
                        <span v-else 
                            @click="resource.resourceType == 'collection' && $event.stopImmediatePropagation() && $event.stopPropagation()"
                            @dblclick="resource.resourceType== 'collection' && rename(resource)">{{ resource.name }}</span>
                    </span>
                </div>
            </div>
            <div class="file-list-item__prop modified" v-show="!hideColumns.modified" :title="moment(resource.date).format('lll')">
                <div class="long-format">{{ moment(resource.date).format('lll')}}</div>
                <div class="short-format">{{ moment(resource.date).format('DD/MM/YYYY')}}</div>
            </div>
            <div class="file-list-item__prop modifiedBy" v-show="!hideColumns.modifiedBy" :title="resource.owner"><div>{{ resource.owner }}</div></div>
            <div class="file-list-item__prop size" v-show="!hideColumns.size" :title="(resource as File).sizeFormat">
                <div v-if="resource.resourceType == 'file'">{{ (resource as File).sizeFormat }}</div>
            </div>
            <div class="file-list-item__prop action">
                <DxDropdown class="action-button" :close-on-content-click="true">
                    <template v-slot:default="{ open }">
                        <div class="three-dots" @click="open">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </template>
                    <template v-slot:dropdown="{ close }">
                        <div class="dx-dropdown-menu">
                            <div v-if="resource.resourceType == 'collection'" class="dx-dropdown-menu__item" @click="close(); rename(resource)">
                                <DxIcon icon="rename"></DxIcon>
                                {{ $t("fileSearchPage.files.rename") }}
                            </div>
                            <div v-if="resource.resourceType == 'file'" class="dx-dropdown-menu__item" @click="close(); downloadFile(resource as IFile); close()">
                                <DxIcon icon="download"></DxIcon>
                                <div class="label">
                                    {{ $t('fileSearchPage.files.download') }}
                                </div>
                            </div>
                            <div class="dx-dropdown-menu__item--color-danger" @click="close(); deleteResource(resource, close);">
                                <DxIcon icon="delete"></DxIcon>
                                <div class="label">
                                    {{ $t('fileSearchPage.files.delete') }}
                                </div>
                            </div>
                        </div>
                    </template>
                </DxDropdown>
            </div>
        </div>
        <DxFileListExpandRow 
            v-if="resource.resourceType == 'collection'"
            :show="viewType==0"
            :expand="expandIds.includes(resource.id!)"
            :viewType="viewType"
            :level="level+1"
            :collection-id="resource.id!"
            :hide-columns="hideColumns"
            @on-view-resource="onViewResource($event, resource)"/>
    </template>
</template>
<script lang="ts" setup>
import { useLoading } from '@/composables/useLoading';
import { useToast } from '@/composables/useToast';
import { ICollection } from '@/models/collection';
import { IResource } from '@/models/resource';
import { ref, defineProps, defineEmits, watch, withDefaults, onMounted } from 'vue';
import DxFilePreview from './DxFilePreview.vue';
import moment from 'moment';
import { File, IFile } from '@/models/file';
import docService from '@/services/doc.service';
import DxDropdown from './DxDropdown.vue';
import DxIcon from './DxIcon.vue';
import collectionService from '@/services/collection.service';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = withDefaults(defineProps<{
    expand: boolean;
    show: boolean;
    collectionId: number;
    hideColumns: any;
    level?: number;
    viewType: number;
}>(), {
    level: 1
}); 
watch(() => props.expand,
() => {
    expandIds.value = [];
    if(props.expand && resources.value.length == 0) {
        fetch();
    }
})
const emits = defineEmits(["onViewResource"]);
const resources = ref<IResource[]>([]);
const expandIds = ref<number[]>([]);
const editingResource = ref<IResource|undefined>();


onMounted(() => {
    document.addEventListener("click", handleClickOutsideInputName);
})

function handleClickOutsideInputName(event: Event) {
    if (!document.querySelector('.name-input')?.contains(event.target as HTMLElement)) {
        editingResource.value = undefined;
    }
}

async function fetch() {
    try {
        useLoading(true);
        const data = await docService.getDocs(0, 10000, props.collectionId);
        resources.value = data.list ?? [];
    } catch (err: any) {
        useToast(err.message);
    } finally {
        useLoading(false);
    }
}

function expandCollection(event:Event, collection: ICollection) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
    if(expandIds.value.includes(collection.id!)) {
        expandIds.value = expandIds.value.filter(item => item != collection.id);
    } else {
        expandIds.value.push(collection.id!)
    }
}
function rename(resouce: IResource) {
    setTimeout(() => {
        editingResource.value = {...resouce};
        setTimeout(() => {
            document.getElementById("name"+resouce.id)?.focus();
            (document.getElementById("name"+resouce.id) as HTMLInputElement)?.select();
        }, 0);
    }, 0);
}

function updateName(resource: IResource) {
    if(!editingResource.value) return;
    const id = editingResource.value!.id;
    const name = editingResource.value!.name;
    const oldName = resource.name;
    resource.name = name;
    editingResource.value = undefined;
    if(name == oldName) return;
    collectionService.rename(id!, name)
    .then(() => {
        useToast({type:'success', message: t("fileSearchPage.files.renameSuccess")});
    }).catch((err) => {
        resource.name = oldName;
        useToast({type:'error', message: err.message});
    })
}

function downloadFile(file: IFile) {
    window.open(file.url, '_blank');
}

function deleteResource(resouce: IResource, callback?: any) {
    useLoading(true)
    if(resouce.resourceType =='file') {
        docService.deleteDoc(resouce.id)
        .then(() => {
            resources.value = (resources.value??[]).filter(item => item.id != resouce.id);
            if(callback) {
                callback();
            }
        })
        .catch((err) => {
            useToast({message: err.message, type: 'error'});
        })
        .finally(() => {
            useLoading(false)
        })
    } else if(resouce.resourceType == 'collection') {
        collectionService.delete(resouce.id!)
        .then(() =>{
            resources.value = (resources.value??[]).filter(item => item.id != resouce.id);
            if(callback) {
                callback();
            }
        })
        .catch((err) => {
            useToast({message: err.message, type: 'error'});
        })
        .finally(() => {
            useLoading(false)
        })
    }
}

function onViewResource(resource: IResource, parentCollection?: ICollection) {
    if(resource.resourceType == 'collection' && parentCollection) {
        let rootParent = resource;
        while(rootParent.parent && !!rootParent.parent?.name) {
            rootParent = rootParent.parent;
        }
        rootParent.parent = parentCollection;
    }
    emits("onViewResource", resource)
}
</script>
<style lang="scss" scoped>
.file-list-item {
    font-size: 11px;
    color: rgba($color1, 0.8);
    display: table-row;
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:not(.file-list-header) {
        &:hover {
            background-color: rgba($color1, 0.02);
        }
    }
    .file-list-item__prop:first-child > div {
        padding-left: var(--expand-padding);
    }
    &__prop {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        cursor: pointer;
        height: 100%;
        white-space: nowrap;
        >div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:not(.file-list-header) {
            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -5px;
                width: 100%;
                border-bottom: 1px solid rgba($color1, 0.15);
            }
        }
        
        &:first-child {
            border-radius: 8px 0 0 8px;
            .type-icon {
                margin-left: 1em;
            }
        }
        &:last-child {
            padding-right: 1em;
            border-radius: 0 8px 8px 0;
        }
    }
    .modified .short-format {
        display: none;
    }
    .size > span, .size > div {
        width: 100%;
        text-align: right;
        padding-right: 10px;
    }
    .name>div {
        display: flex;
        align-items: center;
        font-size: 12px;
        span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .type-icon {
            margin-right: 1em;
            width: 18px;
            height: 26px;
            min-width: 18px;
            border-radius: 2px;
            // overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            &--folder {
                width: 20px;
                margin-right: 1.5em;
            }
            .folder-preview {
                width: 100%;
                height: 100%;
                position: relative;
                &__chevron {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    color: rgba($color1, 0.3); 
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 2em;
                    height: 100%;
                    &:hover {
                        color: $color1;
                    }
                }
                &__icon {
                    height: 100%;
                    margin-left: 50%;
                }
            }
            svg {
                object-fit: contain;
                width: 100%;
            }
        }
    }
}
.name {
    font-size: 12px;
    .label {
        cursor: inherit;
        &--folder {
            cursor: text;
        }
        input {
            border: 1px solid rgba($color1, 0.15);
            outline: 1px solid rgba($color1, 0.15);
            padding: 5px;
            max-width: 100%;
        }
    }
}
.three-dots {
    display: flex;
    align-items: center;
    opacity: 0.6;
    div {
        display: block;
        width: 3px;
        height: 3px;
        background-color: $color1;
        border-radius: 50%;
        &:nth-child(2) {
            margin: 0 3px;
        }
    }
}
.file-list-item:hover {
    .action  svg, .action .three-dots{
        visibility: visible;
    }
}
.action-button {
    position: relative;
    cursor: pointer !important;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg, .three-dots {
        height: 2em;
        visibility: hidden;
    }
}
</style>