<template>
    <div ref="canvasContainer" :style="pdfStyle" class="dx-pdf-viewer"></div>
</template>

<script lang="ts" setup>
import { IGNORE_SYMBOLS_SEARCH_HIGHLIGHT } from '@/commons/const';
import * as pdfjsLib from 'pdfjs-dist';
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.mjs";
import { defineEmits, defineProps, onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps<{
  src: string;
  highlightText?: any;
  page?: number;
  isPreview?: boolean;
}>()
const pdfWorker: pdfjsLib.PDFWorker = pdfjsWorker;
const emits = defineEmits(["onload", "onloadDone", "onloadFail"]);
const canvasContainer = ref();
let pdfDoc: any = null;
let pageRendering = false;
let pageNumPending: number | null = null;
let originPageWidth: any;
const pdfStyle = ref<any>({
    '--scale-factor': 1,
    '--pdf-opacity': 0
});
let currentAbortController: any = null;

watch(() => [props.src, props.highlightText],
() => {
  if(props.src) {
    loadPdf();
  }
},
{
  immediate: true
})
onMounted(() => {
  if(!props.isPreview) {
    window.addEventListener("dxResize", calculateSize);
    window.addEventListener("resize", calculateSize);
  }
})
onUnmounted(() => {
  window.removeEventListener("dxResize", calculateSize);
  window.removeEventListener("dxResize", calculateSize);
})
function calculateSize() {
  if(originPageWidth && canvasContainer.value && canvasContainer.value.querySelector("canvas")) {
    const canvasRect = canvasContainer.value.querySelector("canvas").getBoundingClientRect();
    pdfStyle.value['--scale-factor'] = canvasRect.width / originPageWidth;
  }
}
function loadPdf() {
  if (currentAbortController) {
    currentAbortController.abort();
  }
  currentAbortController = new AbortController();
  const signal = currentAbortController.signal;

  pdfStyle.value['--pdf-opacity'] = 0;
  if(canvasContainer.value) {
    canvasContainer.value.scrollIntoView();
    canvasContainer.value.innerHTML = '';
  }
  emits("onload");
  pdfjsLib.getDocument({
    url: props.src, 
    worker: pdfWorker,
    cMapUrl: '/resources/cmaps/',
    cMapPacked: true

  }).promise.then(async (_pdfDoc: any) => {
    if (signal.aborted) {
      return;
    }
    pdfDoc = _pdfDoc;
    const numPages = props.isPreview?  Math.min(1, pdfDoc.numPages) : pdfDoc.numPages;
    if (canvasContainer.value) {
      let pageHtml = '';
      for (let i = 1; i <= numPages; i++) {
        pageHtml += `<div class="pdf-page">
                        <canvas id="canvas_${i}" style="border:1px solid black;"></canvas>
                        ${!props.isPreview ? `<div class="textLayer" id="text_${i}"></div>` : '' }
                      </div>`;
      }
      canvasContainer.value.innerHTML = pageHtml;
      const textContents: any[] = []
      for (let i = 1; i <= numPages && canvasContainer.value; i++) {
        if (signal.aborted) {
          return;
        }
        const canvas = canvasContainer.value.querySelector(`#canvas_${i}`) as HTMLCanvasElement;
        const textContent: any = await renderPage(i, canvas);
        textContents.push(...textContent.items);
      }
      if (signal.aborted) {
          return;
      }
      renderHighlight(textContents);
      pdfStyle.value['--pdf-opacity'] = 1;
    }
  }).catch((err) => {
    if (!signal.aborted) {
      emits("onloadFail", err);
    }
  }).finally(() => {
    if (!signal.aborted) {
      emits("onloadDone");
    }
  }) 
}

async function renderPage(num: number, canvas: HTMLCanvasElement) {
  const empty = {
      items: []
  };
  if(!canvas) return empty;
  const ctx = canvas.getContext('2d');
  pageRendering = true;
  const page:pdfjsLib.PDFPageProxy = await pdfDoc.getPage(num);
  const viewport = page.getViewport({scale: 1});
  const resolution = 2;
  canvas.height = resolution * viewport.height;
  canvas.width  = resolution * viewport.width;
  pdfStyle.value["--pdf-origin-width"] = viewport.width + 'px'
  pdfStyle.value["--pdf-origin-height"] = viewport.height + 'px'
  originPageWidth = viewport.width;

  const renderContext = {
    canvasContext: ctx!,
    viewport: viewport,
    transform: [resolution, 0, 0, resolution, 0, 0]
  };
  const renderTask = page.render(renderContext);
  const textContent = await renderTask.promise
  .then(() => {
    pageRendering = false;
    if (pageNumPending !== null) {
      renderPage(pageNumPending, canvas);
      pageNumPending = null;
    }
    return page.getTextContent({ disableNormalization: true });
  });
  if(props.isPreview) {
    return empty;
  }
  const textLayer = canvasContainer.value?.querySelector('#text_'+ num);
  if(!textLayer) return empty;
  try {
    new pdfjsLib.TextLayer({
        textContentSource:  textContent,
        container: textLayer,
        viewport: page.getViewport({scale: 1}),
    }).render();
    calculateSize();
    return textContent;
  } catch {
    return empty;
  }
}

function renderHighlight(textContent: any) {
  if(props.highlightText && canvasContainer.value) {
    const textContentItemsStr: any = [];
    for (const textItem of textContent) {
      if (textItem.str.trim() != '') {
        textContentItemsStr.push(textItem.str);
      }
    }
    const regexStr = props.highlightText
                      .replace(new RegExp(`[${IGNORE_SYMBOLS_SEARCH_HIGHLIGHT.join('')}]`, 'g'), ' ')
                      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                      .replace(/\s+/g, '\\s*');
    const regex = new RegExp(regexStr, 'gi');
    const {matches, matchesLength} = calculateRegExpMatch(regex, textContentItemsStr.join("").replace(new RegExp(`[${IGNORE_SYMBOLS_SEARCH_HIGHLIGHT.join('')}]`, 'g'), ' '));
    const result = convertMatches(matches, matchesLength, textContentItemsStr.map((item: string)=>item.replace(new RegExp(`[${IGNORE_SYMBOLS_SEARCH_HIGHLIGHT.join('')}]`, 'g'), ' ')));
    const divs = canvasContainer.value.querySelectorAll('.textLayer >span');
    renderMatches(result, textContentItemsStr, Array.from(divs).filter((div:any) => div.innerText.trim() != ''))
    if(canvasContainer.value.querySelector(".highlight.selected")) {
      canvasContainer.value.querySelector(".highlight.selected")?.scrollIntoView({block: 'center'});
    } else if(props.page) {
      canvasContainer.value.querySelectorAll(".pdf-page")?.[props.page - 1]?.scrollIntoView({block: 'center'});

    }
  }
}

function convertMatches(matches: number[], matchesLength: number[], textContentItemsStr: any, ) {
    // Early exit if there is nothing to convert.
    if (!matches) {
      return [];
    }
    let i = 0,
      iIndex = 0;
    const end = textContentItemsStr.length - 1;
    const result = [];

    for (let m = 0, mm = matches.length; m < mm; m++) {
      // Calculate the start position.
      let matchIdx = matches[m];

      // Loop over the divIdxs.
      while (i !== end && matchIdx >= iIndex + textContentItemsStr[i].length) {
        iIndex += textContentItemsStr[i].length;
        i++;
      }

      if (i === textContentItemsStr.length) {
        console.error("Could not find a matching mapping");
      }

      const match: any = {
        begin: {
          divIdx: i,
          offset: matchIdx - iIndex,
        },
      };

      // Calculate the end position.
      matchIdx += matchesLength[m];

      // Somewhat the same array as above, but use > instead of >= to get
      // the end position right.
      while (i !== end && matchIdx > iIndex + textContentItemsStr[i].length) {
        iIndex += textContentItemsStr[i].length;
        i++;
      }

      match.end = {
        divIdx: i,
        offset: matchIdx - iIndex,
      };
      result.push(match);
    }
    return result;
  }

function renderMatches(matches: any[], textContentItemsStr: any, textDivs: any) {
  // Early exit if there is nothing to render.
  if (matches.length === 0) {
    return;
  }
  let prevEnd = null;
  const infinity = {
    divIdx: -1,
    offset: undefined,
  };

  function beginText(begin: any, className?: any) {
    const divIdx = begin.divIdx;
    textDivs[divIdx].textContent = "";
    return appendTextToDiv(divIdx, 0, begin.offset, className);
  }

  function appendTextToDiv(divIdx: any, fromOffset: any, toOffset: any, className?: any) {
    let div = textDivs[divIdx];
    if (div.nodeType === Node.TEXT_NODE) {
      const span = document.createElement("span");
      div.before(span);
      span.append(div);
      textDivs[divIdx] = span;
      div = span;
    }
    const content = textContentItemsStr[divIdx].substring(
      fromOffset,
      toOffset
    );
    const node = document.createTextNode(content);
    if (className) {
      const span = document.createElement("span");
      span.className = `${className} appended`;
      span.append(node);
      div.append(span);
      return className.includes("selected") ? span.offsetLeft : 0;
    }
    div.append(node);
    return 0;
  }

  let i0 = 0, i1 = matches.length;

  let lastDivIdx = -1;
  let lastOffset = -1;
  for (let i = i0; i < i1; i++) {
    const match = matches[i];
    const begin = match.begin;
    if (begin.divIdx === lastDivIdx && begin.offset === lastOffset) {
      // It's possible to be in this situation if we searched for a 'f' and we
      // have a ligature 'ff' in the text. The 'ff' has to be highlighted two
      // times.
      continue;
    }
    lastDivIdx = begin.divIdx;
    lastOffset = begin.offset;

    const end = match.end;
    let selectedLeft = 0;

    const isSelected = i == 0;
    const highlightSuffix = isSelected ? " selected" : "";

    // Match inside new div.
    if (!prevEnd || begin.divIdx !== prevEnd.divIdx) {
      // If there was a previous div, then add the text at the end.
      if (prevEnd !== null) {
        appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset);
      }
      // Clear the divs and set the content until the starting point.
      beginText(begin);
    } else {
      appendTextToDiv(prevEnd.divIdx, prevEnd.offset, begin.offset);
    }

    if (begin.divIdx === end.divIdx) {
      selectedLeft = appendTextToDiv(
        begin.divIdx,
        begin.offset,
        end.offset,
        "highlight" + highlightSuffix
      );
    } else {
      selectedLeft = appendTextToDiv(
        begin.divIdx,
        begin.offset,
        infinity.offset,
        "highlight begin" + highlightSuffix
      );
      for (let n0 = begin.divIdx + 1, n1 = end.divIdx; n0 < n1; n0++) {
        textDivs[n0].className = "highlight middle" + highlightSuffix;
      }
      beginText(end, "highlight end");
    }
    prevEnd = end;

  }

  if (prevEnd) {
    appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset);
  }
}

function calculateRegExpMatch(query: any, pageContent: any) {
    let matches = [], matchesLength = [];
    if (!query) {
      return {matches: [],
        matchesLength: []};
    }
    let match;
    while ((match = query.exec(pageContent)) !== null) {
      if (match.index === query.lastIndex) {
          query.lastIndex++;
      }

      if (match[0].length) {
        matches.push(match.index,);
        matchesLength.push(match[0].length);
      }
    }
    return {
      matches: matches,
      matchesLength: matchesLength
    }
  }

</script>

<style scoped lang="scss">
.dx-pdf-viewer {
  width: 100%;
  height: 100%;
  display: grid;
  opacity: var(--pdf-opacity);
  overflow: auto;
  overflow-x: hidden;
  :deep(canvas) {
    border: none !important;
    width: 100% !important;
  }
  :deep(.pdf-page) {
    position: relative;

    // Copy from node_module/pdfjs-dist/web/pdf_viewer.css
    .textLayer{
      position:absolute;
      text-align:initial;
      inset:0;
      overflow:clip;
      opacity:1;
      line-height:1;
      -webkit-text-size-adjust:none;
        -moz-text-size-adjust:none;
              text-size-adjust:none;
      forced-color-adjust:none;
      transform-origin:0 0;
      caret-color:CanvasText;
      z-index:0;
    }

    .textLayer.highlighting{
        touch-action:none;
      }

    .textLayer :is(span, br){
        color:transparent;
        position:absolute;
        white-space:pre;
        cursor:text;
        transform-origin:0% 0%;
      }

    .textLayer > :not(.markedContent),
      .textLayer .markedContent span:not(.markedContent){
        z-index:1;
      }

    .textLayer span.markedContent{
        top:0;
        height:0;
      }

    .textLayer .highlight{
        --highlight-bg-color:rgba(255, 255, 0, 0.2);
        --highlight-selected-bg-color:rgba(255, 255, 0, 0.2);
        --highlight-backdrop-filter:none;
        --highlight-selected-backdrop-filter:none;

        margin:-1px;
        padding:1px;
        background-color:var(--highlight-bg-color);
        -webkit-backdrop-filter:var(--highlight-backdrop-filter);
                backdrop-filter:var(--highlight-backdrop-filter);
        border-radius:4px;
      }

    @media screen and (forced-colors: active){

    .textLayer .highlight{
          --highlight-bg-color:transparent;
          --highlight-selected-bg-color:transparent;
          --highlight-backdrop-filter:var(--hcm-highlight-filter);
          --highlight-selected-backdrop-filter:var(
            --hcm-highlight-selected-filter
          );
      }
        }

    .textLayer .highlight.appended{
          position:initial;
        }

    .textLayer .highlight.begin{
          border-radius:4px 0 0 4px;
        }

    .textLayer .highlight.end{
          border-radius:0 4px 4px 0;
        }

    .textLayer .highlight.middle{
          border-radius:0;
        }

    .textLayer .highlight.selected{
          background-color:var(--highlight-selected-bg-color);
          -webkit-backdrop-filter:var(--highlight-selected-backdrop-filter);
                  backdrop-filter:var(--highlight-selected-backdrop-filter);
        }

    .textLayer ::-moz-selection{
        background:rgba(0 0 255 / 0.25);
        background:color-mix(in srgb, AccentColor, transparent 75%);
      }

    .textLayer ::selection{
        background:rgba(0 0 255 / 0.25);
        background:color-mix(in srgb, AccentColor, transparent 75%);
      }

    .textLayer br::-moz-selection{
        background:transparent;
      }

    .textLayer br::selection{
        background:transparent;
      }

    .textLayer .endOfContent{
        display:block;
        position:absolute;
        inset:100% 0 0;
        z-index:0;
        cursor:default;
        -webkit-user-select:none;
          -moz-user-select:none;
                user-select:none;
      }

    .textLayer .endOfContent.active{
          top:0;
        }
  }
}

</style>
