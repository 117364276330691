<template>
    <div class="wrapper" @keyup.enter="submit">
        <h5 class="title">{{  $t('newCollection.title') }}</h5>
        <input name="name" class="dx-input" :placeholder="$t('newCollection.name')" v-model="name"/>
        <button class="dx-button dx-button-primary dx-button-sm" :disabled="!name" @click="submit">{{ $t('newCollection.okButton')}}</button>
   </div>
</template>
<script lang="ts" setup>
import { useLoading } from '@/composables/useLoading';
import { useToast } from '@/composables/useToast';
import collectionService from '@/services/collection.service';
import { ref, defineEmits, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emits = defineEmits(["onUpdated"]);
const props = defineProps<{
    parentId?: number;
}>()
const name = ref();
function submit() {
    useLoading(true);  
    collectionService.create(name.value, props.parentId)
    .then(() => {
        emits('onUpdated');
        useToast({type:'success', message: t("newCollection.successMessage")})
    })
    .catch((err) => {
        useToast({
            type:'error', 
            message: err.message
        });
    })
    .finally(() => {
        useLoading(false)    
    })
}
</script>
<style lang="scss" scoped>
.wrapper {
    max-width: 100%;
    width: 350px;
    padding: 0 2em 2em 2em;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1em;
    .title {
        margin-bottom: 0;
    }
}
</style>