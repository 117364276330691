import { IResource } from "./resource";

export interface ICollection extends IResource {
    isEmpty?: boolean;
}

export class Collection implements ICollection {
    resourceType = 'collection' as const;
    id?: any;
    name = '';
    isEmpty?: boolean;
    date = '';
    owner = '';
    parent?: ICollection;
    constructor(obj: any) {
        if(obj) {
            this.id = obj.id;
            this.name = obj.name;
            this.owner = obj.owner;
            this.date = obj.date;
            this.isEmpty = obj.isEmpty;
            this.parent = obj.parent;
        }
    }
}