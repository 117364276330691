<template>
    <div class="file-list"
         ref="elRef"
         :class="{
            'list': viewType == 0, 'grid': viewType == 1,
            'full-width': isFullWidth
            }">
        <div class="file-list__colgroup">
            <div class="file-list__colgroup__col name"></div>
            <div class="file-list__colgroup__col modified" v-show="!hideColumns.modified"></div>
            <div class="file-list__colgroup__col modifiedBy" v-show="!hideColumns.modifiedBy"></div>
            <div class="file-list__colgroup__col size" v-show="!hideColumns.size"></div>
            <div class="file-list__colgroup__col action"></div>
        </div>
        <div class="file-list-header file-list-item">
            <div class="file-list-item__prop name" @click="sort('name')">
                <span>
                    {{ $t('fileSearchPage.files.colName') }}
                    <dx-icon v-show="data.sortBy == 'name'" :class="data.sort" class="sort-icon" icon="chevron-top" />
                </span>
            </div> 
            <div class="file-list-item__prop modified" v-show="!hideColumns.modified"><span>{{$t('fileSearchPage.files.colDate')}}</span></div>
            <div class="file-list-item__prop modifiedBy" v-show="!hideColumns.modifiedBy"><span>{{$t('fileSearchPage.files.colOwner')}}</span></div>
            <div class="file-list-item__prop size" v-show="!hideColumns.size" @click="sort('size')">
                <span>
                    {{ $t('fileSearchPage.files.colSize') }}
                    <dx-icon v-show="data.sortBy == 'size'" :class="data.sort" class="sort-icon {{ fieldSort.sort }}" icon="chevron-top" />
                </span>
            </div>
            <div class="file-list-item__prop"></div>
        </div>
        <template v-for="resource in list" :key="resource.id" >
            <div class="file-list-item" @click="onViewResource(resource as IResource)">
                <div class="file-list-item__prop name" :title="resource.name">
                    <div >
                        <div class="type-icon" :class="{'type-icon--folder': resource.resourceType =='collection'}">
                            <DxFilePreview v-if="resource.resourceType=='file'" :file="resource as IResource" :preview-type="viewType == 0 ? 'icon': 'preview'" :small-icon="viewType == 0" />
                            <div class="folder-preview" v-else>
                                <div class="folder-preview__chevron"
                                    v-if="viewType==0 && !(resource as ICollection).isEmpty"
                                    @click="expandCollection($event,resource)">
                                    <DxIcon :icon="expandIds.includes(resource.id!) ? 'chevron-down' : 'chevron-top'"/>
                                </div>
                                <DxIcon :icon="viewType == 0 ? 'folder-sm' : 'folder'" class="folder-preview__icon"/>
                            </div>
                        </div>
                        <span class="label" :class="{'label--folder': resource.resourceType=='collection'}">
                            <input v-if="editingResource?.id==resource.id" 
                                :id="'name'+resource.id"
                                class="name-input"
                                @click="$event.stopPropagation()"
                                @keyup.enter="updateName(resource)"
                                @focusout="updateName(resource)"
                                v-model="editingResource!.name"/>
                            <span v-else 
                                @click="resource.resourceType == 'collection' && $event.stopImmediatePropagation() && $event.stopPropagation()"
                                @dblclick="resource.resourceType== 'collection' && rename(resource)">{{ resource.name }}</span>
                        </span>
                    </div>
                </div>
                <div class="file-list-item__prop modified" v-show="!hideColumns.modified" :title="moment(resource.date).format('lll')">
                    <div class="long-format">{{ moment(resource.date).format('lll')}}</div>
                    <div class="short-format">{{ moment(resource.date).format('DD/MM/YYYY')}}</div>
                </div>
                <div class="file-list-item__prop modifiedBy" v-show="!hideColumns.modifiedBy" :title="resource.owner"><div>{{ resource.owner }}</div></div>
                <div class="file-list-item__prop size" v-show="!hideColumns.size" :title="(resource as File).sizeFormat">
                    <div v-if="resource.resourceType == 'file'">{{ (resource as File).sizeFormat }}</div>
                </div>
                <div class="file-list-item__prop action">
                    <DxDropdown class="action-button" :close-on-content-click="true">
                        <template v-slot:default="{ open }">
                            <div class="three-dots" @click="open">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </template>
                        <template v-slot:dropdown="{ close }">
                            <div class="dx-dropdown-menu">
                                <div v-if="resource.resourceType == 'collection'" class="dx-dropdown-menu__item" @click="rename(resource)" >
                                    <DxIcon icon="rename"></DxIcon>
                                    <div class="label">
                                        {{ $t("fileSearchPage.files.rename") }}
                                    </div>
                                </div>
                                <div v-if="resource.resourceType == 'file'" class="dx-dropdown-menu__item" @click="close(); downloadFile(resource as IFile); close()">
                                    <dx-icon icon="download"></dx-icon>
                                    <div class="label">
                                        {{ $t('fileSearchPage.files.download') }}
                                    </div>
                                </div>
                                <div class="dx-dropdown-menu__item--color-danger" @click="close(); deleteResource(resource, close);">
                                    <dx-icon icon="delete"></dx-icon>
                                    <div class="label">
                                        {{ $t('fileSearchPage.files.delete') }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </DxDropdown>
                </div>
            </div>
            <DxFileListExpandRow 
                v-if="resource.resourceType == 'collection'"
                :show="viewType==0" 
                :expand="expandIds.includes(resource.id!)"
                :viewType="viewType"
                :collection-id="resource.id!"
                :hide-columns="hideColumns"
                @on-view-resource="onViewResource($event,  resource)"/>
        </template>
    </div>
</template>
<script setup lang="ts">
import { useToast } from '@/composables/useToast';
import { File, IFile } from '@/models/file';
import { IPageAble } from '@/models/page';
import docService from '@/services/doc.service';
import moment from 'moment';
import { computed, defineEmits, defineProps, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import DxDropdown from './DxDropdown.vue';
import DxFilePreview from './DxFilePreview.vue';
import DxIcon from './DxIcon.vue';
import { IResource } from '@/models/resource';
import collectionService from '@/services/collection.service';
import { useLoading } from '@/composables/useLoading';
import { ICollection } from '@/models/collection';
import DxFileListExpandRow from './DxFileListExpandRow.vue';
import { useI18n } from 'vue-i18n';

const elRef = ref();
const { t } = useI18n();

const props = defineProps<{
    viewType: number,
    data: IPageAble<IResource>
}>();

const emits = defineEmits(["update:data", "onViewResource"]);

const hideColumns = reactive({
    modified: false,
    modifiedBy: false,
    size: false
})
const isFullWidth = ref(false);

const expandIds = ref<number[]>([]);
const editingResource = ref<IResource|undefined>();

watch(()=>props.data.list, () => {
    expandIds.value = []
})

const list = computed(()=> {
    return Array.from(new Set((props.data.list??[]))).sort((item1:IResource, item2: IResource) => {
        const sortBy: string = props?.data?.sortBy ?? "";
        if(sortBy) {
            const value1 = (item1 as any)[sortBy];
            const value2 = (item2 as any)[sortBy];
            let cmp = 0;
            if(typeof value1 == 'string') {
                cmp = ('' + value1).localeCompare('' + value2);
            } else {
                cmp = value1 - value2;
            }
            return props.data.sort == 'desc' ? -cmp : cmp;
        } 
        return 0;
    })
})

onMounted(() => {
    window.addEventListener('dxResize', calculateColumnStyle);
    window.addEventListener('resize', calculateColumnStyle);
    document.addEventListener("click", handleClickOutsideInputName);
    calculateColumnStyle();
})

function handleClickOutsideInputName(event: Event) {
    if (!document.querySelector('.name-input')?.contains(event.target as HTMLElement)) {
        editingResource.value = undefined;
    }
}

onUnmounted(() => {
    window.removeEventListener('dxResize', calculateColumnStyle);
    window.removeEventListener('resize', calculateColumnStyle);
})

function calculateColumnStyle() {
    const rect = elRef.value.parentElement.getBoundingClientRect();
    const width = rect.width;
    hideColumns.modified = width <= 500;
    hideColumns.modifiedBy = width <= 400;
    hideColumns.size = width <= 300;
    if(width <= 300) {
        isFullWidth.value = true;
    } else {
        isFullWidth.value = false;
    }
}

function sort(column:string) {
    const value = props.data;
    if(props.data.sortBy == column) {
        value.sort = value.sort == 'asc' ? 'desc' : 'asc';
        emits("update:data", value);
    }
    else {
        value.sortBy = column;
        value.sort = 'asc';
        emits("update:data", value);
    }
    
}

function downloadFile(file: IFile) {
    window.open(file.url, '_blank');
}
function rename(resouce: IResource) {
    setTimeout(() => {
        editingResource.value = {...resouce};
        setTimeout(() => {
            document.getElementById("name"+resouce.id)?.focus();
            (document.getElementById("name"+resouce.id) as HTMLInputElement)?.select();
        }, 0);
    }, 0);
}

function updateName(resource: IResource) {
    if(!editingResource.value) return;
    const id = editingResource.value!.id;
    const name = editingResource.value!.name;
    const oldName = resource.name;
    resource.name = name;
    editingResource.value = undefined;
    if(name == oldName) return;
    collectionService.rename(id!, name)
    .then(() => {
        useToast({type:'success', message: t("fileSearchPage.files.renameSuccess")});
    }).catch((err) => {
        resource.name = oldName;
        useToast({type:'error', message: err.message});
    })
}

function deleteResource(resouce: IResource, callback?: any) {
    useLoading(true)
    if(resouce.resourceType =='file') {
        docService.deleteDoc(resouce.id)
        .then(() => {
            let data = props.data;
            data.list = data.list?.filter(item => item.id != resouce.id);
            if(callback) {
                callback();
            }
        })
        .catch((err) => {
            useToast({message: err.message, type: 'error'});
        })
        .finally(() => {
            useLoading(false)
        })
    } else if(resouce.resourceType == 'collection') {
        collectionService.delete(resouce.id!)
        .then(() =>{
            let data = props.data;
            data.list = data.list?.filter(item => item.id != resouce.id);
            if(callback) {
                callback();
            }
        })
        .catch((err) => {
            useToast({message: err.message, type: 'error'});
        })
        .finally(() => {
            useLoading(false)
        })
    }
}

function expandCollection(event:Event, collection: ICollection) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
    editingResource.value = undefined;
    if(expandIds.value.includes(collection.id!)) {
        expandIds.value = expandIds.value.filter(item => item != collection.id);
    } else {
        expandIds.value.push(collection.id!)
    }
}

async function onViewResource(resource: IResource, parentCollection?: ICollection) {
    if(resource.resourceType == 'collection' && parentCollection) {
        let rootParent = resource;
        while(rootParent.parent && !!rootParent.parent?.name) {
            rootParent = rootParent.parent;
        }
        rootParent.parent = parentCollection;
    }
    emits("onViewResource", resource);
} 

</script>
<style lang="scss" scoped>
.three-dots {
    display: flex;
    align-items: center;
    opacity: 0.6;
    div {
        display: block;
        width: 3px;
        height: 3px;
        background-color: $color1;
        border-radius: 50%;
        &:nth-child(2) {
            margin: 0 3px;
        }
    }
}
@mixin grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(140px, 100%), 1fr));
    gap: 30px;
    @media only screen and (min-width: 769px) {
        padding: 25px;
    }
    .file-list-header, .file-list__colgroup {
        display: none !important;
    }
    .file-list-item {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 5px;
        text-align: center;
        border-radius: 10px;
        padding: 22px 17px;
        &:hover {
            background-color: rgba($color1, 0.1);
        }
        .modifiedBy, .modified, .size {
            display: none;
        }
        .name>div{
            word-wrap: break-word;
            display: flex;
            flex-direction: column;
            .label {
                width: 100%;
                padding: 0 5px;

                input {
                    border: 0px solid rgba($color1, 0.6);
                }
            }
            .type-icon {
                position: relative;
                width: 100%;
                object-fit: contain;
                overflow: hidden;
                border-radius: 10px;
                margin-bottom: 1em;
                &::before {
                    content: "";
                    margin-bottom: 150%;
                    display: block;
                }
                :deep(.dx-file-preview), .folder-preview {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                }
                .folder-preview {
                    display: flex;
                    align-items: center;
                    justify-self: center;
                    svg {
                        width: 100%;
                    }
                }
            }
        }
        .action-button {
            position: absolute;
            top: 0;
            right: 10px;
            svg, .three-dots {
                height: 22px;
                @media only screen and (max-width: 768px) {
                    visibility: visible;
                }
            }
        } 
    }
}
.file-list {
    width: 100%;
    max-height: 100%;
    &.list {
        @media only screen and (min-width: 769px) {
            display: table;
            table-layout: fixed;
            border-collapse: separate;
            border-spacing: 0 10px;
            margin-top: -10px;
            width: calc(100% - 60px);
            margin-left: 30px;
            &.full-width {
                width: 100%;
                margin-left: 0;
                .file-list-item__prop:first-child {
                    padding-left: 1.5em;
                }
                .file-list-item__prop:last-child {
                    padding-right: 1.5em;
                }
                .file-list-item__prop {
                    border-radius: 0px !important;
                    &:first-child::after, &:last-child::after {
                        width: 100%;
                    }
                }
                :deep(.expand-container) {
                    .file-list-item__prop:first-child {
                    padding-left: 1.5em;
                }
                }
            }
            .file-list__colgroup {
                display: table-column-group;
                &__col {
                    display: table-column;
                    &.modified {
                        width: 120px;
                    }
                    &.modifiedBy {
                        width: 120px;
                    }
                    &.size {
                        width: 80px;
                    }
                    &.action {
                        width: 30px;
                    }
                }
            }
            .file-list-item.file-list-header {
                top: 0;
                font-weight: 600;
                color: $color1;
                z-index: 1;
                position: sticky;
                height: 40px;
                .file-list-item__prop  {
                    cursor: pointer;
                    position: relative;
                    background-color: var(--background-color);
                    overflow: visible;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: rgba(white, 0.6);
                        z-index: 0;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        border-bottom: 1px solid rgba($color1, 0.15);
                    }
                    &:first-child::after {
                        left: -30px;
                        width: calc(100% + 30px);
                    }
                    &:last-child::after {
                        width: calc(100% + 30px);
                    }
                    &.h-size span{
                        width: 100%;
                        text-align: right;
                        padding-right: 10px;
                    }
                    span {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        user-select: none;
                    }
                }
            }
            .file-list-item {
                font-size: 11px;
                color: rgba($color1, 0.8);
                display: table-row;
                height: 45px;
                overflow: hidden;
                text-overflow: ellipsis;
                &:not(.file-list-header) {
                    &:hover {
                        background-color: rgba($color1, 0.02);
                    }
                }
                &.expand-container {
                    .file-list-item__prop:first-child {
                        padding-left: 2em;
                    }
                }
                &__prop {
                    position: relative;
                    display: table-cell;
                    vertical-align: middle;
                    cursor: pointer;
                    height: 100%;
                    white-space: nowrap;
                    >div {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &:not(.file-list-header) {
                        &::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: -5px;
                            width: 100%;
                            border-bottom: 1px solid rgba($color1, 0.15);
                        }
                    }
                    
                    &:first-child {
                        border-radius: 8px 0 0 8px;
                        .type-icon {
                            margin-left: 1em;
                        }
                    }
                    &:last-child {
                        padding-right: 1em;
                        border-radius: 0 8px 8px 0;
                    }
                }
                .modified .short-format {
                    display: none;
                }
                .size > span, .size > div {
                    width: 100%;
                    text-align: right;
                    padding-right: 10px;
                }
                .name>div {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .type-icon {
                        margin-right: 1em;
                        width: 18px;
                        height: 26px;
                        min-width: 18px;
                        border-radius: 2px;
                        // overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &--folder {
                            width: 20px;
                            margin-right: 1.5em;
                        }
                        .folder-preview {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            &__chevron {
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateX(-50%) translateY(-50%);
                                color: rgba($color1, 0.3); 
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 2em;
                                height: 100%;
                                &:hover {
                                    color: $color1;
                                }
                            }
                            &__icon {
                                height: 100%;
                                margin-left: 50%;
                            }
                        }
                        svg {
                            object-fit: contain;
                            width: 100%;
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 768px) {
            @include grid;
            .file-list-item {
                &:hover:not(.file-list-header) {
                    background-color: initial;
                }
                .action-button{
                   >svg, .three-dots {
                        visibility: visible;
                    }
                } 
            }
        }
    }
    &.grid {
        @include grid;
    }

    .file-list-item:hover {
        .action  svg, .action .three-dots{
            visibility: visible;
        }
    }
    .name {
        font-size: 12px;
        .label {
            cursor: inherit;
            &--folder {
                cursor: text;
            }
            input {
                border: 1px solid rgba($color1, 0.15);
                outline: 1px solid rgba($color1, 0.15);
                padding: 5px;
                max-width: 100%;
            }
        }
    }
    .modified, .size{
        font-size: 11px;
        cursor: default;
    }

    .action-button {
        position: relative;
        cursor: pointer !important;
        display: flex;
        justify-content: center;
        align-items: center;
        > svg, .three-dots {
            height: 2em;
            visibility: hidden;
        }
    }
    .sort-icon {
        margin-left: 1em;
    }
    .sort-icon.desc {
        transform: rotate(180deg);
    }
}
</style>